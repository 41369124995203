/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApp = /* GraphQL */ `
  mutation CreateApp(
    $input: CreateAppInput!
    $condition: ModelAppConditionInput
  ) {
    createApp(input: $input, condition: $condition) {
      id
      standard
      mode
      paymentMode
      enableMonthlyReport
      maintenanceSchedule
      maintenanceScheduleDisplayTimeFormat
      adminNotificationCertCompletion
      appMinimumVolume
      appVolumeDiscount
      appDiscount
      appDiscountExpiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApp = /* GraphQL */ `
  mutation UpdateApp(
    $input: UpdateAppInput!
    $condition: ModelAppConditionInput
  ) {
    updateApp(input: $input, condition: $condition) {
      id
      standard
      mode
      paymentMode
      enableMonthlyReport
      maintenanceSchedule
      maintenanceScheduleDisplayTimeFormat
      adminNotificationCertCompletion
      appMinimumVolume
      appVolumeDiscount
      appDiscount
      appDiscountExpiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApp = /* GraphQL */ `
  mutation DeleteApp(
    $input: DeleteAppInput!
    $condition: ModelAppConditionInput
  ) {
    deleteApp(input: $input, condition: $condition) {
      id
      standard
      mode
      paymentMode
      enableMonthlyReport
      maintenanceSchedule
      maintenanceScheduleDisplayTimeFormat
      adminNotificationCertCompletion
      appMinimumVolume
      appVolumeDiscount
      appDiscount
      appDiscountExpiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      ACN
      govId
      self
      createdAt
      country
      name
      businessName
      preferredName
      displayName
      industry
      type
      email
      phone
      addressStreet
      addressSuburb
      addressState
      addressPostcode
      addressCountry
      ANZSIC
      headOfficeStreetName
      headOfficeSuburb
      headOfficeState
      headOfficePostcode
      headOfficeCountry
      postalStreetName
      postalSuburb
      postalState
      postalPostcode
      postalCountry
      websiteDomain
      emailDomain
      websiteDomains
      emailDomains
      annualRevenue
      enableEmailDomainMonitoring
      numEmployees
      website
      deleted
      certificationLevel
      confirmed
      addedBy
      addedTo
      addedAs
      addedInProgress
      owners
      updatedAt
      __typename
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      ACN
      govId
      self
      createdAt
      country
      name
      businessName
      preferredName
      displayName
      industry
      type
      email
      phone
      addressStreet
      addressSuburb
      addressState
      addressPostcode
      addressCountry
      ANZSIC
      headOfficeStreetName
      headOfficeSuburb
      headOfficeState
      headOfficePostcode
      headOfficeCountry
      postalStreetName
      postalSuburb
      postalState
      postalPostcode
      postalCountry
      websiteDomain
      emailDomain
      websiteDomains
      emailDomains
      annualRevenue
      enableEmailDomainMonitoring
      numEmployees
      website
      deleted
      certificationLevel
      confirmed
      addedBy
      addedTo
      addedAs
      addedInProgress
      owners
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      ACN
      govId
      self
      createdAt
      country
      name
      businessName
      preferredName
      displayName
      industry
      type
      email
      phone
      addressStreet
      addressSuburb
      addressState
      addressPostcode
      addressCountry
      ANZSIC
      headOfficeStreetName
      headOfficeSuburb
      headOfficeState
      headOfficePostcode
      headOfficeCountry
      postalStreetName
      postalSuburb
      postalState
      postalPostcode
      postalCountry
      websiteDomain
      emailDomain
      websiteDomains
      emailDomains
      annualRevenue
      enableEmailDomainMonitoring
      numEmployees
      website
      deleted
      certificationLevel
      confirmed
      addedBy
      addedTo
      addedAs
      addedInProgress
      owners
      updatedAt
      __typename
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      orgCountry
      name
      location
      entityType
      self
      createdAt
      commencementDate
      type
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      supplyChainCustomers
      membershipCustomers
      MSPCustomers
      referrerCustomers
      franchiseCustomers
      consultantCustomers
      vendorCustomers
      supplyChainReferrals
      membershipReferrals
      MSPReferrals
      referrerReferrals
      franchiseReferrals
      consultantReferrals
      vendorReferrals
      commission
      recruitedBy
      firstName
      lastName
      email
      phone
      role
      isActive
      isSetup
      isOfferSetup
      setupAt
      discount
      minimumVolume
      subscriptionDiscount
      onboardStep
      onboardCompletedAt
      claimURL
      codeExpiry
      volumeDiscount
      website
      logo
      favicon
      uploadedCount
      pendingABNs
      pendingCCIDs
      pendingPartnerOrgs
      addOrgStep
      dashboardType
      referredBy
      entityName
      industry
      ACN
      ANZIC
      orgPhone
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      annualPartnerFee
      certBundle
      quickStartStep
      quickStartAddAdmin
      quickStartCert
      quickStartAddClient
      quickStartGAPAssessment
      quickStartSubscription
      quickStartDistribution
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      orgCountry
      name
      location
      entityType
      self
      createdAt
      commencementDate
      type
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      supplyChainCustomers
      membershipCustomers
      MSPCustomers
      referrerCustomers
      franchiseCustomers
      consultantCustomers
      vendorCustomers
      supplyChainReferrals
      membershipReferrals
      MSPReferrals
      referrerReferrals
      franchiseReferrals
      consultantReferrals
      vendorReferrals
      commission
      recruitedBy
      firstName
      lastName
      email
      phone
      role
      isActive
      isSetup
      isOfferSetup
      setupAt
      discount
      minimumVolume
      subscriptionDiscount
      onboardStep
      onboardCompletedAt
      claimURL
      codeExpiry
      volumeDiscount
      website
      logo
      favicon
      uploadedCount
      pendingABNs
      pendingCCIDs
      pendingPartnerOrgs
      addOrgStep
      dashboardType
      referredBy
      entityName
      industry
      ACN
      ANZIC
      orgPhone
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      annualPartnerFee
      certBundle
      quickStartStep
      quickStartAddAdmin
      quickStartCert
      quickStartAddClient
      quickStartGAPAssessment
      quickStartSubscription
      quickStartDistribution
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      orgCountry
      name
      location
      entityType
      self
      createdAt
      commencementDate
      type
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      supplyChainCustomers
      membershipCustomers
      MSPCustomers
      referrerCustomers
      franchiseCustomers
      consultantCustomers
      vendorCustomers
      supplyChainReferrals
      membershipReferrals
      MSPReferrals
      referrerReferrals
      franchiseReferrals
      consultantReferrals
      vendorReferrals
      commission
      recruitedBy
      firstName
      lastName
      email
      phone
      role
      isActive
      isSetup
      isOfferSetup
      setupAt
      discount
      minimumVolume
      subscriptionDiscount
      onboardStep
      onboardCompletedAt
      claimURL
      codeExpiry
      volumeDiscount
      website
      logo
      favicon
      uploadedCount
      pendingABNs
      pendingCCIDs
      pendingPartnerOrgs
      addOrgStep
      dashboardType
      referredBy
      entityName
      industry
      ACN
      ANZIC
      orgPhone
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      annualPartnerFee
      certBundle
      quickStartStep
      quickStartAddAdmin
      quickStartCert
      quickStartAddClient
      quickStartGAPAssessment
      quickStartSubscription
      quickStartDistribution
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      orgCountry
      self
      createdAt
      partners
      users
      purchasedLevel
      certifications
      certificationId
      certification {
        id
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          ccid
          orgId
          status
          role
          title
          firstName
          lastName
          email
          auditEmail
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              ccid
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                title
                description
                notApplicable
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationSignedAt
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          ivo
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      deleted
      upgraded
      guidePurchased
      guidePurchasedDate
      isActive
      isSetup
      onboardCompletedAt
      onboardStep
      onboardBy
      internalSpecialist
      externalSpecialist
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      orgCountry
      self
      createdAt
      partners
      users
      purchasedLevel
      certifications
      certificationId
      certification {
        id
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          ccid
          orgId
          status
          role
          title
          firstName
          lastName
          email
          auditEmail
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              ccid
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                title
                description
                notApplicable
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationSignedAt
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          ivo
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      deleted
      upgraded
      guidePurchased
      guidePurchasedDate
      isActive
      isSetup
      onboardCompletedAt
      onboardStep
      onboardBy
      internalSpecialist
      externalSpecialist
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      orgCountry
      self
      createdAt
      partners
      users
      purchasedLevel
      certifications
      certificationId
      certification {
        id
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          ccid
          orgId
          status
          role
          title
          firstName
          lastName
          email
          auditEmail
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              ccid
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                title
                description
                notApplicable
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationSignedAt
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          ivo
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      deleted
      upgraded
      guidePurchased
      guidePurchasedDate
      isActive
      isSetup
      onboardCompletedAt
      onboardStep
      onboardBy
      internalSpecialist
      externalSpecialist
      updatedAt
      __typename
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      self
      createdAt
      firstName
      lastName
      email
      phone
      role
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      self
      createdAt
      firstName
      lastName
      email
      phone
      role
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      self
      createdAt
      firstName
      lastName
      email
      phone
      role
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      country
      organisations
      self
      createdAt
      customer
      firstName
      lastName
      email
      phone
      phoneVerified
      role
      type
      organisation
      owner
      preferredMFA
      mfaUpdatedAt
      loginAt
      loginLocation
      loginDevice
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      country
      organisations
      self
      createdAt
      customer
      firstName
      lastName
      email
      phone
      phoneVerified
      role
      type
      organisation
      owner
      preferredMFA
      mfaUpdatedAt
      loginAt
      loginLocation
      loginDevice
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      country
      organisations
      self
      createdAt
      customer
      firstName
      lastName
      email
      phone
      phoneVerified
      role
      type
      organisation
      owner
      preferredMFA
      mfaUpdatedAt
      loginAt
      loginLocation
      loginDevice
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createCertification = /* GraphQL */ `
  mutation CreateCertification(
    $input: CreateCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    createCertification(input: $input, condition: $condition) {
      id
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      level
      progressId
      progress {
        id
        level
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        status
        role
        title
        firstName
        lastName
        email
        auditEmail
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            ccid
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              title
              description
              notApplicable
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationSignedAt
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        ivo
        owners
        updatedAt
        __typename
      }
      totalStep
      duration
      purchasedDate
      source
      standard
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const updateCertification = /* GraphQL */ `
  mutation UpdateCertification(
    $input: UpdateCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    updateCertification(input: $input, condition: $condition) {
      id
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      level
      progressId
      progress {
        id
        level
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        status
        role
        title
        firstName
        lastName
        email
        auditEmail
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            ccid
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              title
              description
              notApplicable
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationSignedAt
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        ivo
        owners
        updatedAt
        __typename
      }
      totalStep
      duration
      purchasedDate
      source
      standard
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const deleteCertification = /* GraphQL */ `
  mutation DeleteCertification(
    $input: DeleteCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    deleteCertification(input: $input, condition: $condition) {
      id
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      level
      progressId
      progress {
        id
        level
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        status
        role
        title
        firstName
        lastName
        email
        auditEmail
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            ccid
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              title
              description
              notApplicable
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationSignedAt
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        ivo
        owners
        updatedAt
        __typename
      }
      totalStep
      duration
      purchasedDate
      source
      standard
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const createCertificationProgress = /* GraphQL */ `
  mutation CreateCertificationProgress(
    $input: CreateCertificationProgressInput!
    $condition: ModelCertificationProgressConditionInput
  ) {
    createCertificationProgress(input: $input, condition: $condition) {
      id
      level
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      status
      role
      title
      firstName
      lastName
      email
      auditEmail
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          ccid
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            title
            description
            notApplicable
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationSignedAt
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      ivo
      owners
      updatedAt
      __typename
    }
  }
`;
export const updateCertificationProgress = /* GraphQL */ `
  mutation UpdateCertificationProgress(
    $input: UpdateCertificationProgressInput!
    $condition: ModelCertificationProgressConditionInput
  ) {
    updateCertificationProgress(input: $input, condition: $condition) {
      id
      level
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      status
      role
      title
      firstName
      lastName
      email
      auditEmail
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          ccid
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            title
            description
            notApplicable
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationSignedAt
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      ivo
      owners
      updatedAt
      __typename
    }
  }
`;
export const deleteCertificationProgress = /* GraphQL */ `
  mutation DeleteCertificationProgress(
    $input: DeleteCertificationProgressInput!
    $condition: ModelCertificationProgressConditionInput
  ) {
    deleteCertificationProgress(input: $input, condition: $condition) {
      id
      level
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      status
      role
      title
      firstName
      lastName
      email
      auditEmail
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          ccid
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            title
            description
            notApplicable
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationSignedAt
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      ivo
      owners
      updatedAt
      __typename
    }
  }
`;
export const createRequirement = /* GraphQL */ `
  mutation CreateRequirement(
    $input: CreateRequirementInput!
    $condition: ModelRequirementConditionInput
  ) {
    createRequirement(input: $input, condition: $condition) {
      id
      progressId
      requirementId
      ABN
      ccid
      orgId
      self
      createdAt
      attestationStatus
      attestationNote
      attestedBy
      notApplicableDetails
      detail {
        requirementId
        baseId
        title
        description
        notApplicable
        __typename
      }
      assignedToITSpecialist
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const updateRequirement = /* GraphQL */ `
  mutation UpdateRequirement(
    $input: UpdateRequirementInput!
    $condition: ModelRequirementConditionInput
  ) {
    updateRequirement(input: $input, condition: $condition) {
      id
      progressId
      requirementId
      ABN
      ccid
      orgId
      self
      createdAt
      attestationStatus
      attestationNote
      attestedBy
      notApplicableDetails
      detail {
        requirementId
        baseId
        title
        description
        notApplicable
        __typename
      }
      assignedToITSpecialist
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const deleteRequirement = /* GraphQL */ `
  mutation DeleteRequirement(
    $input: DeleteRequirementInput!
    $condition: ModelRequirementConditionInput
  ) {
    deleteRequirement(input: $input, condition: $condition) {
      id
      progressId
      requirementId
      ABN
      ccid
      orgId
      self
      createdAt
      attestationStatus
      attestationNote
      attestedBy
      notApplicableDetails
      detail {
        requirementId
        baseId
        title
        description
        notApplicable
        __typename
      }
      assignedToITSpecialist
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount(
    $input: CreateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    createDiscount(input: $input, condition: $condition) {
      id
      customer
      ruleId
      rule {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      code
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount(
    $input: UpdateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    updateDiscount(input: $input, condition: $condition) {
      id
      customer
      ruleId
      rule {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      code
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount(
    $input: DeleteDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    deleteDiscount(input: $input, condition: $condition) {
      id
      customer
      ruleId
      rule {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      code
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createCommission = /* GraphQL */ `
  mutation CreateCommission(
    $input: CreateCommissionInput!
    $condition: ModelCommissionConditionInput
  ) {
    createCommission(input: $input, condition: $condition) {
      id
      partner
      saleId
      ruleId
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateCommission = /* GraphQL */ `
  mutation UpdateCommission(
    $input: UpdateCommissionInput!
    $condition: ModelCommissionConditionInput
  ) {
    updateCommission(input: $input, condition: $condition) {
      id
      partner
      saleId
      ruleId
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteCommission = /* GraphQL */ `
  mutation DeleteCommission(
    $input: DeleteCommissionInput!
    $condition: ModelCommissionConditionInput
  ) {
    deleteCommission(input: $input, condition: $condition) {
      id
      partner
      saleId
      ruleId
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createRule = /* GraphQL */ `
  mutation CreateRule(
    $input: CreateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    createRule(input: $input, condition: $condition) {
      id
      type
      rate
      levels
      applyType
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateRule = /* GraphQL */ `
  mutation UpdateRule(
    $input: UpdateRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    updateRule(input: $input, condition: $condition) {
      id
      type
      rate
      levels
      applyType
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteRule = /* GraphQL */ `
  mutation DeleteRule(
    $input: DeleteRuleInput!
    $condition: ModelRuleConditionInput
  ) {
    deleteRule(input: $input, condition: $condition) {
      id
      type
      rate
      levels
      applyType
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createApplyRule = /* GraphQL */ `
  mutation CreateApplyRule(
    $input: CreateApplyRuleInput!
    $condition: ModelApplyRuleConditionInput
  ) {
    createApplyRule(input: $input, condition: $condition) {
      id
      partnerRuleId
      partner
      startDate
      endDate
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateApplyRule = /* GraphQL */ `
  mutation UpdateApplyRule(
    $input: UpdateApplyRuleInput!
    $condition: ModelApplyRuleConditionInput
  ) {
    updateApplyRule(input: $input, condition: $condition) {
      id
      partnerRuleId
      partner
      startDate
      endDate
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteApplyRule = /* GraphQL */ `
  mutation DeleteApplyRule(
    $input: DeleteApplyRuleInput!
    $condition: ModelApplyRuleConditionInput
  ) {
    deleteApplyRule(input: $input, condition: $condition) {
      id
      partnerRuleId
      partner
      startDate
      endDate
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createPartnerRule = /* GraphQL */ `
  mutation CreatePartnerRule(
    $input: CreatePartnerRuleInput!
    $condition: ModelPartnerRuleConditionInput
  ) {
    createPartnerRule(input: $input, condition: $condition) {
      id
      name
      users
      associatedRules
      globalRules
      recruitCommission
      accessRules
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updatePartnerRule = /* GraphQL */ `
  mutation UpdatePartnerRule(
    $input: UpdatePartnerRuleInput!
    $condition: ModelPartnerRuleConditionInput
  ) {
    updatePartnerRule(input: $input, condition: $condition) {
      id
      name
      users
      associatedRules
      globalRules
      recruitCommission
      accessRules
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deletePartnerRule = /* GraphQL */ `
  mutation DeletePartnerRule(
    $input: DeletePartnerRuleInput!
    $condition: ModelPartnerRuleConditionInput
  ) {
    deletePartnerRule(input: $input, condition: $condition) {
      id
      name
      users
      associatedRules
      globalRules
      recruitCommission
      accessRules
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createAccessRule = /* GraphQL */ `
  mutation CreateAccessRule(
    $input: CreateAccessRuleInput!
    $condition: ModelAccessRuleConditionInput
  ) {
    createAccessRule(input: $input, condition: $condition) {
      id
      target
      permissions
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateAccessRule = /* GraphQL */ `
  mutation UpdateAccessRule(
    $input: UpdateAccessRuleInput!
    $condition: ModelAccessRuleConditionInput
  ) {
    updateAccessRule(input: $input, condition: $condition) {
      id
      target
      permissions
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteAccessRule = /* GraphQL */ `
  mutation DeleteAccessRule(
    $input: DeleteAccessRuleInput!
    $condition: ModelAccessRuleConditionInput
  ) {
    deleteAccessRule(input: $input, condition: $condition) {
      id
      target
      permissions
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createAuditRequest = /* GraphQL */ `
  mutation CreateAuditRequest(
    $input: CreateAuditRequestInput!
    $condition: ModelAuditRequestConditionInput
  ) {
    createAuditRequest(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      certificationId
      level
      attempted
      datePaid
      status
      reviewer
      reviewerABN
      reviewerCCID
      reviewerOrgId
      reviewNotes
      ivo
      acknowledged
      acknowledgedBy
      acknowledgedAt
      auditStartedAt
      auditCompletedAt
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateAuditRequest = /* GraphQL */ `
  mutation UpdateAuditRequest(
    $input: UpdateAuditRequestInput!
    $condition: ModelAuditRequestConditionInput
  ) {
    updateAuditRequest(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      certificationId
      level
      attempted
      datePaid
      status
      reviewer
      reviewerABN
      reviewerCCID
      reviewerOrgId
      reviewNotes
      ivo
      acknowledged
      acknowledgedBy
      acknowledgedAt
      auditStartedAt
      auditCompletedAt
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteAuditRequest = /* GraphQL */ `
  mutation DeleteAuditRequest(
    $input: DeleteAuditRequestInput!
    $condition: ModelAuditRequestConditionInput
  ) {
    deleteAuditRequest(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      certificationId
      level
      attempted
      datePaid
      status
      reviewer
      reviewerABN
      reviewerCCID
      reviewerOrgId
      reviewNotes
      ivo
      acknowledged
      acknowledgedBy
      acknowledgedAt
      auditStartedAt
      auditCompletedAt
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createActivityFeed = /* GraphQL */ `
  mutation CreateActivityFeed(
    $input: CreateActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    createActivityFeed(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      type
      name
      level
      standard
      email
      authoriser
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateActivityFeed = /* GraphQL */ `
  mutation UpdateActivityFeed(
    $input: UpdateActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    updateActivityFeed(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      type
      name
      level
      standard
      email
      authoriser
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteActivityFeed = /* GraphQL */ `
  mutation DeleteActivityFeed(
    $input: DeleteActivityFeedInput!
    $condition: ModelActivityFeedConditionInput
  ) {
    deleteActivityFeed(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      type
      name
      level
      standard
      email
      authoriser
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      status
      type
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      status
      type
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      status
      type
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createSale = /* GraphQL */ `
  mutation CreateSale(
    $input: CreateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    createSale(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      email
      item
      certID
      status
      discountRate
      amountPaid
      datePaid
      type
      public
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateSale = /* GraphQL */ `
  mutation UpdateSale(
    $input: UpdateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    updateSale(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      email
      item
      certID
      status
      discountRate
      amountPaid
      datePaid
      type
      public
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteSale = /* GraphQL */ `
  mutation DeleteSale(
    $input: DeleteSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    deleteSale(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      email
      item
      certID
      status
      discountRate
      amountPaid
      datePaid
      type
      public
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      name
      status
      totalReceivers
      success
      fail
      month
      year
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      name
      status
      totalReceivers
      success
      fail
      month
      year
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      name
      status
      totalReceivers
      success
      fail
      month
      year
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createPartnerReport = /* GraphQL */ `
  mutation CreatePartnerReport(
    $input: CreatePartnerReportInput!
    $condition: ModelPartnerReportConditionInput
  ) {
    createPartnerReport(input: $input, condition: $condition) {
      id
      partner
      reportId
      reportDoc
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updatePartnerReport = /* GraphQL */ `
  mutation UpdatePartnerReport(
    $input: UpdatePartnerReportInput!
    $condition: ModelPartnerReportConditionInput
  ) {
    updatePartnerReport(input: $input, condition: $condition) {
      id
      partner
      reportId
      reportDoc
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deletePartnerReport = /* GraphQL */ `
  mutation DeletePartnerReport(
    $input: DeletePartnerReportInput!
    $condition: ModelPartnerReportConditionInput
  ) {
    deletePartnerReport(input: $input, condition: $condition) {
      id
      partner
      reportId
      reportDoc
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createRecommendationTool = /* GraphQL */ `
  mutation CreateRecommendationTool(
    $input: CreateRecommendationToolInput!
    $condition: ModelRecommendationToolConditionInput
  ) {
    createRecommendationTool(input: $input, condition: $condition) {
      id
      result
      progress {
        id
        value
        __typename
      }
      ABN
      ccid
      orgId
      eligibleRequirements
      owners
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRecommendationTool = /* GraphQL */ `
  mutation UpdateRecommendationTool(
    $input: UpdateRecommendationToolInput!
    $condition: ModelRecommendationToolConditionInput
  ) {
    updateRecommendationTool(input: $input, condition: $condition) {
      id
      result
      progress {
        id
        value
        __typename
      }
      ABN
      ccid
      orgId
      eligibleRequirements
      owners
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRecommendationTool = /* GraphQL */ `
  mutation DeleteRecommendationTool(
    $input: DeleteRecommendationToolInput!
    $condition: ModelRecommendationToolConditionInput
  ) {
    deleteRecommendationTool(input: $input, condition: $condition) {
      id
      result
      progress {
        id
        value
        __typename
      }
      ABN
      ccid
      orgId
      eligibleRequirements
      owners
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createClaimCode = /* GraphQL */ `
  mutation CreateClaimCode(
    $input: CreateClaimCodeInput!
    $condition: ModelClaimCodeConditionInput
  ) {
    createClaimCode(input: $input, condition: $condition) {
      id
      code
      expired
      ABN
      ccid
      orgId
      level
      partnerName
      name
      logo
      pageTitle
      description
      dashboardType
      tally
      totalTally
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClaimCode = /* GraphQL */ `
  mutation UpdateClaimCode(
    $input: UpdateClaimCodeInput!
    $condition: ModelClaimCodeConditionInput
  ) {
    updateClaimCode(input: $input, condition: $condition) {
      id
      code
      expired
      ABN
      ccid
      orgId
      level
      partnerName
      name
      logo
      pageTitle
      description
      dashboardType
      tally
      totalTally
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClaimCode = /* GraphQL */ `
  mutation DeleteClaimCode(
    $input: DeleteClaimCodeInput!
    $condition: ModelClaimCodeConditionInput
  ) {
    deleteClaimCode(input: $input, condition: $condition) {
      id
      code
      expired
      ABN
      ccid
      orgId
      level
      partnerName
      name
      logo
      pageTitle
      description
      dashboardType
      tally
      totalTally
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCheckout = /* GraphQL */ `
  mutation CreateCheckout(
    $input: CreateCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    createCheckout(input: $input, condition: $condition) {
      id
      sessionId
      ABN
      ccid
      orgId
      country
      item
      status
      source
      userId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCheckout = /* GraphQL */ `
  mutation UpdateCheckout(
    $input: UpdateCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    updateCheckout(input: $input, condition: $condition) {
      id
      sessionId
      ABN
      ccid
      orgId
      country
      item
      status
      source
      userId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCheckout = /* GraphQL */ `
  mutation DeleteCheckout(
    $input: DeleteCheckoutInput!
    $condition: ModelCheckoutConditionInput
  ) {
    deleteCheckout(input: $input, condition: $condition) {
      id
      sessionId
      ABN
      ccid
      orgId
      country
      item
      status
      source
      userId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSMSChallenge = /* GraphQL */ `
  mutation CreateSMSChallenge(
    $input: CreateSMSChallengeInput!
    $condition: ModelSMSChallengeConditionInput
  ) {
    createSMSChallenge(input: $input, condition: $condition) {
      id
      phone
      code
      userId
      codeExpiredAt
      codeResendAllowedAt
      status
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSMSChallenge = /* GraphQL */ `
  mutation UpdateSMSChallenge(
    $input: UpdateSMSChallengeInput!
    $condition: ModelSMSChallengeConditionInput
  ) {
    updateSMSChallenge(input: $input, condition: $condition) {
      id
      phone
      code
      userId
      codeExpiredAt
      codeResendAllowedAt
      status
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSMSChallenge = /* GraphQL */ `
  mutation DeleteSMSChallenge(
    $input: DeleteSMSChallengeInput!
    $condition: ModelSMSChallengeConditionInput
  ) {
    deleteSMSChallenge(input: $input, condition: $condition) {
      id
      phone
      code
      userId
      codeExpiredAt
      codeResendAllowedAt
      status
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPartnerInvite = /* GraphQL */ `
  mutation CreatePartnerInvite(
    $input: CreatePartnerInviteInput!
    $condition: ModelPartnerInviteConditionInput
  ) {
    createPartnerInvite(input: $input, condition: $condition) {
      id
      partnerABN
      partnerCCID
      partnerOrgId
      partnerName
      ABN
      ccid
      orgId
      orgCountry
      level
      dashboardType
      status
      specialProgram
      standard
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePartnerInvite = /* GraphQL */ `
  mutation UpdatePartnerInvite(
    $input: UpdatePartnerInviteInput!
    $condition: ModelPartnerInviteConditionInput
  ) {
    updatePartnerInvite(input: $input, condition: $condition) {
      id
      partnerABN
      partnerCCID
      partnerOrgId
      partnerName
      ABN
      ccid
      orgId
      orgCountry
      level
      dashboardType
      status
      specialProgram
      standard
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePartnerInvite = /* GraphQL */ `
  mutation DeletePartnerInvite(
    $input: DeletePartnerInviteInput!
    $condition: ModelPartnerInviteConditionInput
  ) {
    deletePartnerInvite(input: $input, condition: $condition) {
      id
      partnerABN
      partnerCCID
      partnerOrgId
      partnerName
      ABN
      ccid
      orgId
      orgCountry
      level
      dashboardType
      status
      specialProgram
      standard
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPartnerOffer = /* GraphQL */ `
  mutation CreatePartnerOffer(
    $input: CreatePartnerOfferInput!
    $condition: ModelPartnerOfferConditionInput
  ) {
    createPartnerOffer(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      discount
      dashboard
      expiry
      isActive
      isOneTimeOffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePartnerOffer = /* GraphQL */ `
  mutation UpdatePartnerOffer(
    $input: UpdatePartnerOfferInput!
    $condition: ModelPartnerOfferConditionInput
  ) {
    updatePartnerOffer(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      discount
      dashboard
      expiry
      isActive
      isOneTimeOffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePartnerOffer = /* GraphQL */ `
  mutation DeletePartnerOffer(
    $input: DeletePartnerOfferInput!
    $condition: ModelPartnerOfferConditionInput
  ) {
    deletePartnerOffer(input: $input, condition: $condition) {
      id
      ABN
      ccid
      orgId
      discount
      dashboard
      expiry
      isActive
      isOneTimeOffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPartnerOfferAccept = /* GraphQL */ `
  mutation CreatePartnerOfferAccept(
    $input: CreatePartnerOfferAcceptInput!
    $condition: ModelPartnerOfferAcceptConditionInput
  ) {
    createPartnerOfferAccept(input: $input, condition: $condition) {
      id
      offerId
      standard
      ABN
      ccid
      orgId
      orgCountry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePartnerOfferAccept = /* GraphQL */ `
  mutation UpdatePartnerOfferAccept(
    $input: UpdatePartnerOfferAcceptInput!
    $condition: ModelPartnerOfferAcceptConditionInput
  ) {
    updatePartnerOfferAccept(input: $input, condition: $condition) {
      id
      offerId
      standard
      ABN
      ccid
      orgId
      orgCountry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePartnerOfferAccept = /* GraphQL */ `
  mutation DeletePartnerOfferAccept(
    $input: DeletePartnerOfferAcceptInput!
    $condition: ModelPartnerOfferAcceptConditionInput
  ) {
    deletePartnerOfferAccept(input: $input, condition: $condition) {
      id
      offerId
      standard
      ABN
      ccid
      orgId
      orgCountry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      id
      type
      token
      ABN
      ccid
      orgId
      questionnaire {
        id
        requirement
        question
        __typename
      }
      maximumLevel
      dashboard
      isActive
      isSetup
      activeAt
      submitedAt
      standard
      archived
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      type
      token
      ABN
      ccid
      orgId
      questionnaire {
        id
        requirement
        question
        __typename
      }
      maximumLevel
      dashboard
      isActive
      isSetup
      activeAt
      submitedAt
      standard
      archived
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment(
    $input: DeleteAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    deleteAssessment(input: $input, condition: $condition) {
      id
      type
      token
      ABN
      ccid
      orgId
      questionnaire {
        id
        requirement
        question
        __typename
      }
      maximumLevel
      dashboard
      isActive
      isSetup
      activeAt
      submitedAt
      standard
      archived
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssessmentResult = /* GraphQL */ `
  mutation CreateAssessmentResult(
    $input: CreateAssessmentResultInput!
    $condition: ModelAssessmentResultConditionInput
  ) {
    createAssessmentResult(input: $input, condition: $condition) {
      id
      type
      assessmentId
      assessmentToken
      ABN
      ccid
      orgId
      name
      result
      answers {
        id
        requirement
        value
        __typename
      }
      implementedRequirements
      completedBy
      session
      verifiedVia
      verifiedData
      verifiedAt
      completedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssessmentResult = /* GraphQL */ `
  mutation UpdateAssessmentResult(
    $input: UpdateAssessmentResultInput!
    $condition: ModelAssessmentResultConditionInput
  ) {
    updateAssessmentResult(input: $input, condition: $condition) {
      id
      type
      assessmentId
      assessmentToken
      ABN
      ccid
      orgId
      name
      result
      answers {
        id
        requirement
        value
        __typename
      }
      implementedRequirements
      completedBy
      session
      verifiedVia
      verifiedData
      verifiedAt
      completedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssessmentResult = /* GraphQL */ `
  mutation DeleteAssessmentResult(
    $input: DeleteAssessmentResultInput!
    $condition: ModelAssessmentResultConditionInput
  ) {
    deleteAssessmentResult(input: $input, condition: $condition) {
      id
      type
      assessmentId
      assessmentToken
      ABN
      ccid
      orgId
      name
      result
      answers {
        id
        requirement
        value
        __typename
      }
      implementedRequirements
      completedBy
      session
      verifiedVia
      verifiedData
      verifiedAt
      completedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSpecialOffer = /* GraphQL */ `
  mutation CreateSpecialOffer(
    $input: CreateSpecialOfferInput!
    $condition: ModelSpecialOfferConditionInput
  ) {
    createSpecialOffer(input: $input, condition: $condition) {
      id
      name
      isActive
      expiryAccActive
      expiryDays
      goldGrant
      silverGrant
      bronzeGrant
      banner
      logo
      hero
      grantedLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSpecialOffer = /* GraphQL */ `
  mutation UpdateSpecialOffer(
    $input: UpdateSpecialOfferInput!
    $condition: ModelSpecialOfferConditionInput
  ) {
    updateSpecialOffer(input: $input, condition: $condition) {
      id
      name
      isActive
      expiryAccActive
      expiryDays
      goldGrant
      silverGrant
      bronzeGrant
      banner
      logo
      hero
      grantedLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSpecialOffer = /* GraphQL */ `
  mutation DeleteSpecialOffer(
    $input: DeleteSpecialOfferInput!
    $condition: ModelSpecialOfferConditionInput
  ) {
    deleteSpecialOffer(input: $input, condition: $condition) {
      id
      name
      isActive
      expiryAccActive
      expiryDays
      goldGrant
      silverGrant
      bronzeGrant
      banner
      logo
      hero
      grantedLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSpecialOfferAccept = /* GraphQL */ `
  mutation CreateSpecialOfferAccept(
    $input: CreateSpecialOfferAcceptInput!
    $condition: ModelSpecialOfferAcceptConditionInput
  ) {
    createSpecialOfferAccept(input: $input, condition: $condition) {
      id
      offerId
      offerName
      ABN
      ccid
      orgId
      status
      dashboard
      firstEmailActive
      firstEmailReminder
      firstEmailTemplate
      secondEmailActive
      secondEmailReminder
      secondEmailTemplate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSpecialOfferAccept = /* GraphQL */ `
  mutation UpdateSpecialOfferAccept(
    $input: UpdateSpecialOfferAcceptInput!
    $condition: ModelSpecialOfferAcceptConditionInput
  ) {
    updateSpecialOfferAccept(input: $input, condition: $condition) {
      id
      offerId
      offerName
      ABN
      ccid
      orgId
      status
      dashboard
      firstEmailActive
      firstEmailReminder
      firstEmailTemplate
      secondEmailActive
      secondEmailReminder
      secondEmailTemplate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSpecialOfferAccept = /* GraphQL */ `
  mutation DeleteSpecialOfferAccept(
    $input: DeleteSpecialOfferAcceptInput!
    $condition: ModelSpecialOfferAcceptConditionInput
  ) {
    deleteSpecialOfferAccept(input: $input, condition: $condition) {
      id
      offerId
      offerName
      ABN
      ccid
      orgId
      status
      dashboard
      firstEmailActive
      firstEmailReminder
      firstEmailTemplate
      secondEmailActive
      secondEmailReminder
      secondEmailTemplate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCAPReferral = /* GraphQL */ `
  mutation CreateCAPReferral(
    $input: CreateCAPReferralInput!
    $condition: ModelCAPReferralConditionInput
  ) {
    createCAPReferral(input: $input, condition: $condition) {
      code
      self
      partnerABN
      partnerCCID
      partnerOrgId
      email
      linkToReferrer
      firstName
      lastName
      isActive
      certBundle
      dashboard
      referralPartners
      specialOffer
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateCAPReferral = /* GraphQL */ `
  mutation UpdateCAPReferral(
    $input: UpdateCAPReferralInput!
    $condition: ModelCAPReferralConditionInput
  ) {
    updateCAPReferral(input: $input, condition: $condition) {
      code
      self
      partnerABN
      partnerCCID
      partnerOrgId
      email
      linkToReferrer
      firstName
      lastName
      isActive
      certBundle
      dashboard
      referralPartners
      specialOffer
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteCAPReferral = /* GraphQL */ `
  mutation DeleteCAPReferral(
    $input: DeleteCAPReferralInput!
    $condition: ModelCAPReferralConditionInput
  ) {
    deleteCAPReferral(input: $input, condition: $condition) {
      code
      self
      partnerABN
      partnerCCID
      partnerOrgId
      email
      linkToReferrer
      firstName
      lastName
      isActive
      certBundle
      dashboard
      referralPartners
      specialOffer
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createCertBundle = /* GraphQL */ `
  mutation CreateCertBundle(
    $input: CreateCertBundleInput!
    $condition: ModelCertBundleConditionInput
  ) {
    createCertBundle(input: $input, condition: $condition) {
      id
      self
      bundleName
      bronzeQTY
      silverQTY
      goldQTY
      platinumQTY
      diamondQTY
      isActive
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updateCertBundle = /* GraphQL */ `
  mutation UpdateCertBundle(
    $input: UpdateCertBundleInput!
    $condition: ModelCertBundleConditionInput
  ) {
    updateCertBundle(input: $input, condition: $condition) {
      id
      self
      bundleName
      bronzeQTY
      silverQTY
      goldQTY
      platinumQTY
      diamondQTY
      isActive
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deleteCertBundle = /* GraphQL */ `
  mutation DeleteCertBundle(
    $input: DeleteCertBundleInput!
    $condition: ModelCertBundleConditionInput
  ) {
    deleteCertBundle(input: $input, condition: $condition) {
      id
      self
      bundleName
      bronzeQTY
      silverQTY
      goldQTY
      platinumQTY
      diamondQTY
      isActive
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createPendingPartner = /* GraphQL */ `
  mutation CreatePendingPartner(
    $input: CreatePendingPartnerInput!
    $condition: ModelPendingPartnerConditionInput
  ) {
    createPendingPartner(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      govId
      orgCountry
      location
      entityType
      self
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      initialDashboardType
      name
      addedAs
      industry
      type
      ACN
      ANZIC
      orgPhone
      website
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      firstName
      lastName
      email
      workPhone
      phone
      role
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      commencementDate
      annualPartnerFee
      subscriptionDiscount
      codeExpiry
      minimumVolume
      volumeDiscount
      discount
      referredBy
      certBundle
      isSubmissionDone
      isPartnerSigned
      partnerSignedAt
      isAdminSigned
      adminSignedAt
      signingSession
      envelopeId
      envelopeSentAt
      isOnboarded
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const updatePendingPartner = /* GraphQL */ `
  mutation UpdatePendingPartner(
    $input: UpdatePendingPartnerInput!
    $condition: ModelPendingPartnerConditionInput
  ) {
    updatePendingPartner(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      govId
      orgCountry
      location
      entityType
      self
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      initialDashboardType
      name
      addedAs
      industry
      type
      ACN
      ANZIC
      orgPhone
      website
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      firstName
      lastName
      email
      workPhone
      phone
      role
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      commencementDate
      annualPartnerFee
      subscriptionDiscount
      codeExpiry
      minimumVolume
      volumeDiscount
      discount
      referredBy
      certBundle
      isSubmissionDone
      isPartnerSigned
      partnerSignedAt
      isAdminSigned
      adminSignedAt
      signingSession
      envelopeId
      envelopeSentAt
      isOnboarded
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const deletePendingPartner = /* GraphQL */ `
  mutation DeletePendingPartner(
    $input: DeletePendingPartnerInput!
    $condition: ModelPendingPartnerConditionInput
  ) {
    deletePendingPartner(input: $input, condition: $condition) {
      ABN
      ccid
      orgId
      govId
      orgCountry
      location
      entityType
      self
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      initialDashboardType
      name
      addedAs
      industry
      type
      ACN
      ANZIC
      orgPhone
      website
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      firstName
      lastName
      email
      workPhone
      phone
      role
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      commencementDate
      annualPartnerFee
      subscriptionDiscount
      codeExpiry
      minimumVolume
      volumeDiscount
      discount
      referredBy
      certBundle
      isSubmissionDone
      isPartnerSigned
      partnerSignedAt
      isAdminSigned
      adminSignedAt
      signingSession
      envelopeId
      envelopeSentAt
      isOnboarded
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const createUserInvite = /* GraphQL */ `
  mutation CreateUserInvite(
    $input: CreateUserInviteInput!
    $condition: ModelUserInviteConditionInput
  ) {
    createUserInvite(input: $input, condition: $condition) {
      id
      userId
      email
      phone
      fullName
      firstName
      lastName
      role
      status
      ABN
      ccid
      orgId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInvite = /* GraphQL */ `
  mutation UpdateUserInvite(
    $input: UpdateUserInviteInput!
    $condition: ModelUserInviteConditionInput
  ) {
    updateUserInvite(input: $input, condition: $condition) {
      id
      userId
      email
      phone
      fullName
      firstName
      lastName
      role
      status
      ABN
      ccid
      orgId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInvite = /* GraphQL */ `
  mutation DeleteUserInvite(
    $input: DeleteUserInviteInput!
    $condition: ModelUserInviteConditionInput
  ) {
    deleteUserInvite(input: $input, condition: $condition) {
      id
      userId
      email
      phone
      fullName
      firstName
      lastName
      role
      status
      ABN
      ccid
      orgId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion(
    $input: CreateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    createRegion(input: $input, condition: $condition) {
      countryCode
      countryName
      description
      supportedLanguages
      localePaths
      discount
      discountAppliedLevels
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion(
    $input: UpdateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    updateRegion(input: $input, condition: $condition) {
      countryCode
      countryName
      description
      supportedLanguages
      localePaths
      discount
      discountAppliedLevels
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion(
    $input: DeleteRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    deleteRegion(input: $input, condition: $condition) {
      countryCode
      countryName
      description
      supportedLanguages
      localePaths
      discount
      discountAppliedLevels
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGovID = /* GraphQL */ `
  mutation CreateGovID(
    $input: CreateGovIDInput!
    $condition: ModelGovIDConditionInput
  ) {
    createGovID(input: $input, condition: $condition) {
      countryCode
      desctipion
      identifiers
      format
      idName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGovID = /* GraphQL */ `
  mutation UpdateGovID(
    $input: UpdateGovIDInput!
    $condition: ModelGovIDConditionInput
  ) {
    updateGovID(input: $input, condition: $condition) {
      countryCode
      desctipion
      identifiers
      format
      idName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGovID = /* GraphQL */ `
  mutation DeleteGovID(
    $input: DeleteGovIDInput!
    $condition: ModelGovIDConditionInput
  ) {
    deleteGovID(input: $input, condition: $condition) {
      countryCode
      desctipion
      identifiers
      format
      idName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPendingExternalSpecialist = /* GraphQL */ `
  mutation CreatePendingExternalSpecialist(
    $input: CreatePendingExternalSpecialistInput!
    $condition: ModelPendingExternalSpecialistConditionInput
  ) {
    createPendingExternalSpecialist(input: $input, condition: $condition) {
      id
      ABN
      orgId
      ccid
      name
      tradingName
      person
      email
      phone
      website
      addedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePendingExternalSpecialist = /* GraphQL */ `
  mutation UpdatePendingExternalSpecialist(
    $input: UpdatePendingExternalSpecialistInput!
    $condition: ModelPendingExternalSpecialistConditionInput
  ) {
    updatePendingExternalSpecialist(input: $input, condition: $condition) {
      id
      ABN
      orgId
      ccid
      name
      tradingName
      person
      email
      phone
      website
      addedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePendingExternalSpecialist = /* GraphQL */ `
  mutation DeletePendingExternalSpecialist(
    $input: DeletePendingExternalSpecialistInput!
    $condition: ModelPendingExternalSpecialistConditionInput
  ) {
    deletePendingExternalSpecialist(input: $input, condition: $condition) {
      id
      ABN
      orgId
      ccid
      name
      tradingName
      person
      email
      phone
      website
      addedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCertificationSpecialistEngagement = /* GraphQL */ `
  mutation CreateCertificationSpecialistEngagement(
    $input: CreateCertificationSpecialistEngagementInput!
    $condition: ModelCertificationSpecialistEngagementConditionInput
  ) {
    createCertificationSpecialistEngagement(
      input: $input
      condition: $condition
    ) {
      id
      ABN
      orgId
      ccid
      certificationId
      specialistABN
      specialistGovID
      status
      region
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCertificationSpecialistEngagement = /* GraphQL */ `
  mutation UpdateCertificationSpecialistEngagement(
    $input: UpdateCertificationSpecialistEngagementInput!
    $condition: ModelCertificationSpecialistEngagementConditionInput
  ) {
    updateCertificationSpecialistEngagement(
      input: $input
      condition: $condition
    ) {
      id
      ABN
      orgId
      ccid
      certificationId
      specialistABN
      specialistGovID
      status
      region
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCertificationSpecialistEngagement = /* GraphQL */ `
  mutation DeleteCertificationSpecialistEngagement(
    $input: DeleteCertificationSpecialistEngagementInput!
    $condition: ModelCertificationSpecialistEngagementConditionInput
  ) {
    deleteCertificationSpecialistEngagement(
      input: $input
      condition: $condition
    ) {
      id
      ABN
      orgId
      ccid
      certificationId
      specialistABN
      specialistGovID
      status
      region
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEOIRegistration = /* GraphQL */ `
  mutation CreateEOIRegistration(
    $input: CreateEOIRegistrationInput!
    $condition: ModelEOIRegistrationConditionInput
  ) {
    createEOIRegistration(input: $input, condition: $condition) {
      id
      countryCode
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEOIRegistration = /* GraphQL */ `
  mutation UpdateEOIRegistration(
    $input: UpdateEOIRegistrationInput!
    $condition: ModelEOIRegistrationConditionInput
  ) {
    updateEOIRegistration(input: $input, condition: $condition) {
      id
      countryCode
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEOIRegistration = /* GraphQL */ `
  mutation DeleteEOIRegistration(
    $input: DeleteEOIRegistrationInput!
    $condition: ModelEOIRegistrationConditionInput
  ) {
    deleteEOIRegistration(input: $input, condition: $condition) {
      id
      countryCode
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSMBSurvey = /* GraphQL */ `
  mutation CreateSMBSurvey(
    $input: CreateSMBSurveyInput!
    $condition: ModelSMBSurveyConditionInput
  ) {
    createSMBSurvey(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      region
      submitedAt
      results
      surveyReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSMBSurvey = /* GraphQL */ `
  mutation UpdateSMBSurvey(
    $input: UpdateSMBSurveyInput!
    $condition: ModelSMBSurveyConditionInput
  ) {
    updateSMBSurvey(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      region
      submitedAt
      results
      surveyReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSMBSurvey = /* GraphQL */ `
  mutation DeleteSMBSurvey(
    $input: DeleteSMBSurveyInput!
    $condition: ModelSMBSurveyConditionInput
  ) {
    deleteSMBSurvey(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      region
      submitedAt
      results
      surveyReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSMBSurveyDetail = /* GraphQL */ `
  mutation CreateSMBSurveyDetail(
    $input: CreateSMBSurveyDetailInput!
    $condition: ModelSMBSurveyDetailConditionInput
  ) {
    createSMBSurveyDetail(input: $input, condition: $condition) {
      id
      surveyId
      questionId
      response
      recommendation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSMBSurveyDetail = /* GraphQL */ `
  mutation UpdateSMBSurveyDetail(
    $input: UpdateSMBSurveyDetailInput!
    $condition: ModelSMBSurveyDetailConditionInput
  ) {
    updateSMBSurveyDetail(input: $input, condition: $condition) {
      id
      surveyId
      questionId
      response
      recommendation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSMBSurveyDetail = /* GraphQL */ `
  mutation DeleteSMBSurveyDetail(
    $input: DeleteSMBSurveyDetailInput!
    $condition: ModelSMBSurveyDetailConditionInput
  ) {
    deleteSMBSurveyDetail(input: $input, condition: $condition) {
      id
      surveyId
      questionId
      response
      recommendation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSMBLead = /* GraphQL */ `
  mutation CreateSMBLead(
    $input: CreateSMBLeadInput!
    $condition: ModelSMBLeadConditionInput
  ) {
    createSMBLead(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      region
      companyName
      inboundMethod
      refCode
      isPartner
      partnerEOI
      surveyEOI
      quizCompleted
      hasPurchased
      purchaseEOI
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSMBLead = /* GraphQL */ `
  mutation UpdateSMBLead(
    $input: UpdateSMBLeadInput!
    $condition: ModelSMBLeadConditionInput
  ) {
    updateSMBLead(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      region
      companyName
      inboundMethod
      refCode
      isPartner
      partnerEOI
      surveyEOI
      quizCompleted
      hasPurchased
      purchaseEOI
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSMBLead = /* GraphQL */ `
  mutation DeleteSMBLead(
    $input: DeleteSMBLeadInput!
    $condition: ModelSMBLeadConditionInput
  ) {
    deleteSMBLead(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      region
      companyName
      inboundMethod
      refCode
      isPartner
      partnerEOI
      surveyEOI
      quizCompleted
      hasPurchased
      purchaseEOI
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTOTPChallenge = /* GraphQL */ `
  mutation CreateTOTPChallenge(
    $input: CreateTOTPChallengeInput!
    $condition: ModelTOTPChallengeConditionInput
  ) {
    createTOTPChallenge(input: $input, condition: $condition) {
      id
      email
      phone
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTOTPChallenge = /* GraphQL */ `
  mutation UpdateTOTPChallenge(
    $input: UpdateTOTPChallengeInput!
    $condition: ModelTOTPChallengeConditionInput
  ) {
    updateTOTPChallenge(input: $input, condition: $condition) {
      id
      email
      phone
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTOTPChallenge = /* GraphQL */ `
  mutation DeleteTOTPChallenge(
    $input: DeleteTOTPChallengeInput!
    $condition: ModelTOTPChallengeConditionInput
  ) {
    deleteTOTPChallenge(input: $input, condition: $condition) {
      id
      email
      phone
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMailingSubscription = /* GraphQL */ `
  mutation CreateMailingSubscription(
    $input: CreateMailingSubscriptionInput!
    $condition: ModelMailingSubscriptionConditionInput
  ) {
    createMailingSubscription(input: $input, condition: $condition) {
      id
      email
      phone
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMailingSubscription = /* GraphQL */ `
  mutation UpdateMailingSubscription(
    $input: UpdateMailingSubscriptionInput!
    $condition: ModelMailingSubscriptionConditionInput
  ) {
    updateMailingSubscription(input: $input, condition: $condition) {
      id
      email
      phone
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMailingSubscription = /* GraphQL */ `
  mutation DeleteMailingSubscription(
    $input: DeleteMailingSubscriptionInput!
    $condition: ModelMailingSubscriptionConditionInput
  ) {
    deleteMailingSubscription(input: $input, condition: $condition) {
      id
      email
      phone
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInsuranceProvider = /* GraphQL */ `
  mutation CreateInsuranceProvider(
    $input: CreateInsuranceProviderInput!
    $condition: ModelInsuranceProviderConditionInput
  ) {
    createInsuranceProvider(input: $input, condition: $condition) {
      id
      insuranceId
      name
      displayName
      descriptions
      website
      email
      emails
      logo
      regions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInsuranceProvider = /* GraphQL */ `
  mutation UpdateInsuranceProvider(
    $input: UpdateInsuranceProviderInput!
    $condition: ModelInsuranceProviderConditionInput
  ) {
    updateInsuranceProvider(input: $input, condition: $condition) {
      id
      insuranceId
      name
      displayName
      descriptions
      website
      email
      emails
      logo
      regions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInsuranceProvider = /* GraphQL */ `
  mutation DeleteInsuranceProvider(
    $input: DeleteInsuranceProviderInput!
    $condition: ModelInsuranceProviderConditionInput
  ) {
    deleteInsuranceProvider(input: $input, condition: $condition) {
      id
      insuranceId
      name
      displayName
      descriptions
      website
      email
      emails
      logo
      regions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInsuranceQuote = /* GraphQL */ `
  mutation CreateInsuranceQuote(
    $input: CreateInsuranceQuoteInput!
    $condition: ModelInsuranceQuoteConditionInput
  ) {
    createInsuranceQuote(input: $input, condition: $condition) {
      id
      insuranceId
      ccid
      orgId
      title
      firstName
      lastName
      role
      email
      telephone
      sendingEmails
      PIIPCI
      numEmployees
      revenueAmount
      signature
      signatureCompletedAt
      formCompletedAt
      declarationSigned
      pdfReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInsuranceQuote = /* GraphQL */ `
  mutation UpdateInsuranceQuote(
    $input: UpdateInsuranceQuoteInput!
    $condition: ModelInsuranceQuoteConditionInput
  ) {
    updateInsuranceQuote(input: $input, condition: $condition) {
      id
      insuranceId
      ccid
      orgId
      title
      firstName
      lastName
      role
      email
      telephone
      sendingEmails
      PIIPCI
      numEmployees
      revenueAmount
      signature
      signatureCompletedAt
      formCompletedAt
      declarationSigned
      pdfReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInsuranceQuote = /* GraphQL */ `
  mutation DeleteInsuranceQuote(
    $input: DeleteInsuranceQuoteInput!
    $condition: ModelInsuranceQuoteConditionInput
  ) {
    deleteInsuranceQuote(input: $input, condition: $condition) {
      id
      insuranceId
      ccid
      orgId
      title
      firstName
      lastName
      role
      email
      telephone
      sendingEmails
      PIIPCI
      numEmployees
      revenueAmount
      signature
      signatureCompletedAt
      formCompletedAt
      declarationSigned
      pdfReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
