/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onProgressUpdateByID = /* GraphQL */ `
  subscription OnProgressUpdateByID($id: ID!) {
    onProgressUpdateByID(id: $id) {
      id
      level
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      status
      role
      title
      firstName
      lastName
      email
      auditEmail
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          ccid
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            title
            description
            notApplicable
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationSignedAt
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      ivo
      owners
      updatedAt
      __typename
    }
  }
`;
export const onCreateApp = /* GraphQL */ `
  subscription OnCreateApp($filter: ModelSubscriptionAppFilterInput) {
    onCreateApp(filter: $filter) {
      id
      standard
      mode
      paymentMode
      enableMonthlyReport
      maintenanceSchedule
      maintenanceScheduleDisplayTimeFormat
      adminNotificationCertCompletion
      appMinimumVolume
      appVolumeDiscount
      appDiscount
      appDiscountExpiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateApp = /* GraphQL */ `
  subscription OnUpdateApp($filter: ModelSubscriptionAppFilterInput) {
    onUpdateApp(filter: $filter) {
      id
      standard
      mode
      paymentMode
      enableMonthlyReport
      maintenanceSchedule
      maintenanceScheduleDisplayTimeFormat
      adminNotificationCertCompletion
      appMinimumVolume
      appVolumeDiscount
      appDiscount
      appDiscountExpiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteApp = /* GraphQL */ `
  subscription OnDeleteApp($filter: ModelSubscriptionAppFilterInput) {
    onDeleteApp(filter: $filter) {
      id
      standard
      mode
      paymentMode
      enableMonthlyReport
      maintenanceSchedule
      maintenanceScheduleDisplayTimeFormat
      adminNotificationCertCompletion
      appMinimumVolume
      appVolumeDiscount
      appDiscount
      appDiscountExpiredAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrganisation = /* GraphQL */ `
  subscription OnCreateOrganisation(
    $filter: ModelSubscriptionOrganisationFilterInput
  ) {
    onCreateOrganisation(filter: $filter) {
      ABN
      ccid
      orgId
      ACN
      govId
      self
      createdAt
      country
      name
      businessName
      preferredName
      displayName
      industry
      type
      email
      phone
      addressStreet
      addressSuburb
      addressState
      addressPostcode
      addressCountry
      ANZSIC
      headOfficeStreetName
      headOfficeSuburb
      headOfficeState
      headOfficePostcode
      headOfficeCountry
      postalStreetName
      postalSuburb
      postalState
      postalPostcode
      postalCountry
      websiteDomain
      emailDomain
      websiteDomains
      emailDomains
      annualRevenue
      enableEmailDomainMonitoring
      numEmployees
      website
      deleted
      certificationLevel
      confirmed
      addedBy
      addedTo
      addedAs
      addedInProgress
      owners
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOrganisation = /* GraphQL */ `
  subscription OnUpdateOrganisation(
    $filter: ModelSubscriptionOrganisationFilterInput
  ) {
    onUpdateOrganisation(filter: $filter) {
      ABN
      ccid
      orgId
      ACN
      govId
      self
      createdAt
      country
      name
      businessName
      preferredName
      displayName
      industry
      type
      email
      phone
      addressStreet
      addressSuburb
      addressState
      addressPostcode
      addressCountry
      ANZSIC
      headOfficeStreetName
      headOfficeSuburb
      headOfficeState
      headOfficePostcode
      headOfficeCountry
      postalStreetName
      postalSuburb
      postalState
      postalPostcode
      postalCountry
      websiteDomain
      emailDomain
      websiteDomains
      emailDomains
      annualRevenue
      enableEmailDomainMonitoring
      numEmployees
      website
      deleted
      certificationLevel
      confirmed
      addedBy
      addedTo
      addedAs
      addedInProgress
      owners
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOrganisation = /* GraphQL */ `
  subscription OnDeleteOrganisation(
    $filter: ModelSubscriptionOrganisationFilterInput
  ) {
    onDeleteOrganisation(filter: $filter) {
      ABN
      ccid
      orgId
      ACN
      govId
      self
      createdAt
      country
      name
      businessName
      preferredName
      displayName
      industry
      type
      email
      phone
      addressStreet
      addressSuburb
      addressState
      addressPostcode
      addressCountry
      ANZSIC
      headOfficeStreetName
      headOfficeSuburb
      headOfficeState
      headOfficePostcode
      headOfficeCountry
      postalStreetName
      postalSuburb
      postalState
      postalPostcode
      postalCountry
      websiteDomain
      emailDomain
      websiteDomains
      emailDomains
      annualRevenue
      enableEmailDomainMonitoring
      numEmployees
      website
      deleted
      certificationLevel
      confirmed
      addedBy
      addedTo
      addedAs
      addedInProgress
      owners
      updatedAt
      __typename
    }
  }
`;
export const onCreatePartner = /* GraphQL */ `
  subscription OnCreatePartner($filter: ModelSubscriptionPartnerFilterInput) {
    onCreatePartner(filter: $filter) {
      ABN
      ccid
      orgId
      orgCountry
      name
      location
      entityType
      self
      createdAt
      commencementDate
      type
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      supplyChainCustomers
      membershipCustomers
      MSPCustomers
      referrerCustomers
      franchiseCustomers
      consultantCustomers
      vendorCustomers
      supplyChainReferrals
      membershipReferrals
      MSPReferrals
      referrerReferrals
      franchiseReferrals
      consultantReferrals
      vendorReferrals
      commission
      recruitedBy
      firstName
      lastName
      email
      phone
      role
      isActive
      isSetup
      isOfferSetup
      setupAt
      discount
      minimumVolume
      subscriptionDiscount
      onboardStep
      onboardCompletedAt
      claimURL
      codeExpiry
      volumeDiscount
      website
      logo
      favicon
      uploadedCount
      pendingABNs
      pendingCCIDs
      pendingPartnerOrgs
      addOrgStep
      dashboardType
      referredBy
      entityName
      industry
      ACN
      ANZIC
      orgPhone
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      annualPartnerFee
      certBundle
      quickStartStep
      quickStartAddAdmin
      quickStartCert
      quickStartAddClient
      quickStartGAPAssessment
      quickStartSubscription
      quickStartDistribution
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartner = /* GraphQL */ `
  subscription OnUpdatePartner($filter: ModelSubscriptionPartnerFilterInput) {
    onUpdatePartner(filter: $filter) {
      ABN
      ccid
      orgId
      orgCountry
      name
      location
      entityType
      self
      createdAt
      commencementDate
      type
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      supplyChainCustomers
      membershipCustomers
      MSPCustomers
      referrerCustomers
      franchiseCustomers
      consultantCustomers
      vendorCustomers
      supplyChainReferrals
      membershipReferrals
      MSPReferrals
      referrerReferrals
      franchiseReferrals
      consultantReferrals
      vendorReferrals
      commission
      recruitedBy
      firstName
      lastName
      email
      phone
      role
      isActive
      isSetup
      isOfferSetup
      setupAt
      discount
      minimumVolume
      subscriptionDiscount
      onboardStep
      onboardCompletedAt
      claimURL
      codeExpiry
      volumeDiscount
      website
      logo
      favicon
      uploadedCount
      pendingABNs
      pendingCCIDs
      pendingPartnerOrgs
      addOrgStep
      dashboardType
      referredBy
      entityName
      industry
      ACN
      ANZIC
      orgPhone
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      annualPartnerFee
      certBundle
      quickStartStep
      quickStartAddAdmin
      quickStartCert
      quickStartAddClient
      quickStartGAPAssessment
      quickStartSubscription
      quickStartDistribution
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartner = /* GraphQL */ `
  subscription OnDeletePartner($filter: ModelSubscriptionPartnerFilterInput) {
    onDeletePartner(filter: $filter) {
      ABN
      ccid
      orgId
      orgCountry
      name
      location
      entityType
      self
      createdAt
      commencementDate
      type
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      supplyChainCustomers
      membershipCustomers
      MSPCustomers
      referrerCustomers
      franchiseCustomers
      consultantCustomers
      vendorCustomers
      supplyChainReferrals
      membershipReferrals
      MSPReferrals
      referrerReferrals
      franchiseReferrals
      consultantReferrals
      vendorReferrals
      commission
      recruitedBy
      firstName
      lastName
      email
      phone
      role
      isActive
      isSetup
      isOfferSetup
      setupAt
      discount
      minimumVolume
      subscriptionDiscount
      onboardStep
      onboardCompletedAt
      claimURL
      codeExpiry
      volumeDiscount
      website
      logo
      favicon
      uploadedCount
      pendingABNs
      pendingCCIDs
      pendingPartnerOrgs
      addOrgStep
      dashboardType
      referredBy
      entityName
      industry
      ACN
      ANZIC
      orgPhone
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      annualPartnerFee
      certBundle
      quickStartStep
      quickStartAddAdmin
      quickStartCert
      quickStartAddClient
      quickStartGAPAssessment
      quickStartSubscription
      quickStartDistribution
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer($filter: ModelSubscriptionCustomerFilterInput) {
    onCreateCustomer(filter: $filter) {
      ABN
      ccid
      orgId
      orgCountry
      self
      createdAt
      partners
      users
      purchasedLevel
      certifications
      certificationId
      certification {
        id
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          ccid
          orgId
          status
          role
          title
          firstName
          lastName
          email
          auditEmail
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              ccid
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                title
                description
                notApplicable
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationSignedAt
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          ivo
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      deleted
      upgraded
      guidePurchased
      guidePurchasedDate
      isActive
      isSetup
      onboardCompletedAt
      onboardStep
      onboardBy
      internalSpecialist
      externalSpecialist
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer($filter: ModelSubscriptionCustomerFilterInput) {
    onUpdateCustomer(filter: $filter) {
      ABN
      ccid
      orgId
      orgCountry
      self
      createdAt
      partners
      users
      purchasedLevel
      certifications
      certificationId
      certification {
        id
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          ccid
          orgId
          status
          role
          title
          firstName
          lastName
          email
          auditEmail
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              ccid
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                title
                description
                notApplicable
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationSignedAt
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          ivo
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      deleted
      upgraded
      guidePurchased
      guidePurchasedDate
      isActive
      isSetup
      onboardCompletedAt
      onboardStep
      onboardBy
      internalSpecialist
      externalSpecialist
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer($filter: ModelSubscriptionCustomerFilterInput) {
    onDeleteCustomer(filter: $filter) {
      ABN
      ccid
      orgId
      orgCountry
      self
      createdAt
      partners
      users
      purchasedLevel
      certifications
      certificationId
      certification {
        id
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        level
        progressId
        progress {
          id
          level
          self
          createdAt
          certID
          ABN
          ccid
          orgId
          status
          role
          title
          firstName
          lastName
          email
          auditEmail
          mobile
          requirements {
            items {
              id
              progressId
              requirementId
              ABN
              ccid
              orgId
              self
              createdAt
              attestationStatus
              attestationNote
              attestedBy
              notApplicableDetails
              detail {
                requirementId
                baseId
                title
                description
                notApplicable
                __typename
              }
              assignedToITSpecialist
              deleted
              owners
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          step
          envelopeId
          attestationResendTime
          attestationSigned
          attestationSignedAt
          attestationReviewed
          dateLodged
          confetti
          expiredDate
          issuedDate
          completedDate
          workbookDownloadedDate
          excelDownloadedDate
          credlyAcceptedDate
          deleted
          evidenceWorkbookUrl
          evidenceWworksheetUrl
          evidenceNote
          ivo
          owners
          updatedAt
          __typename
        }
        totalStep
        duration
        purchasedDate
        source
        standard
        deleted
        owners
        updatedAt
        __typename
      }
      deleted
      upgraded
      guidePurchased
      guidePurchasedDate
      isActive
      isSetup
      onboardCompletedAt
      onboardStep
      onboardBy
      internalSpecialist
      externalSpecialist
      updatedAt
      __typename
    }
  }
`;
export const onCreateAdmin = /* GraphQL */ `
  subscription OnCreateAdmin($filter: ModelSubscriptionAdminFilterInput) {
    onCreateAdmin(filter: $filter) {
      id
      self
      createdAt
      firstName
      lastName
      email
      phone
      role
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAdmin = /* GraphQL */ `
  subscription OnUpdateAdmin($filter: ModelSubscriptionAdminFilterInput) {
    onUpdateAdmin(filter: $filter) {
      id
      self
      createdAt
      firstName
      lastName
      email
      phone
      role
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAdmin = /* GraphQL */ `
  subscription OnDeleteAdmin($filter: ModelSubscriptionAdminFilterInput) {
    onDeleteAdmin(filter: $filter) {
      id
      self
      createdAt
      firstName
      lastName
      email
      phone
      role
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      ABN
      ccid
      orgId
      country
      organisations
      self
      createdAt
      customer
      firstName
      lastName
      email
      phone
      phoneVerified
      role
      type
      organisation
      owner
      preferredMFA
      mfaUpdatedAt
      loginAt
      loginLocation
      loginDevice
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      ABN
      ccid
      orgId
      country
      organisations
      self
      createdAt
      customer
      firstName
      lastName
      email
      phone
      phoneVerified
      role
      type
      organisation
      owner
      preferredMFA
      mfaUpdatedAt
      loginAt
      loginLocation
      loginDevice
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      ABN
      ccid
      orgId
      country
      organisations
      self
      createdAt
      customer
      firstName
      lastName
      email
      phone
      phoneVerified
      role
      type
      organisation
      owner
      preferredMFA
      mfaUpdatedAt
      loginAt
      loginLocation
      loginDevice
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateCertification = /* GraphQL */ `
  subscription OnCreateCertification(
    $filter: ModelSubscriptionCertificationFilterInput
  ) {
    onCreateCertification(filter: $filter) {
      id
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      level
      progressId
      progress {
        id
        level
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        status
        role
        title
        firstName
        lastName
        email
        auditEmail
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            ccid
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              title
              description
              notApplicable
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationSignedAt
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        ivo
        owners
        updatedAt
        __typename
      }
      totalStep
      duration
      purchasedDate
      source
      standard
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCertification = /* GraphQL */ `
  subscription OnUpdateCertification(
    $filter: ModelSubscriptionCertificationFilterInput
  ) {
    onUpdateCertification(filter: $filter) {
      id
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      level
      progressId
      progress {
        id
        level
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        status
        role
        title
        firstName
        lastName
        email
        auditEmail
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            ccid
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              title
              description
              notApplicable
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationSignedAt
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        ivo
        owners
        updatedAt
        __typename
      }
      totalStep
      duration
      purchasedDate
      source
      standard
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCertification = /* GraphQL */ `
  subscription OnDeleteCertification(
    $filter: ModelSubscriptionCertificationFilterInput
  ) {
    onDeleteCertification(filter: $filter) {
      id
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      level
      progressId
      progress {
        id
        level
        self
        createdAt
        certID
        ABN
        ccid
        orgId
        status
        role
        title
        firstName
        lastName
        email
        auditEmail
        mobile
        requirements {
          items {
            id
            progressId
            requirementId
            ABN
            ccid
            orgId
            self
            createdAt
            attestationStatus
            attestationNote
            attestedBy
            notApplicableDetails
            detail {
              requirementId
              baseId
              title
              description
              notApplicable
              __typename
            }
            assignedToITSpecialist
            deleted
            owners
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        step
        envelopeId
        attestationResendTime
        attestationSigned
        attestationSignedAt
        attestationReviewed
        dateLodged
        confetti
        expiredDate
        issuedDate
        completedDate
        workbookDownloadedDate
        excelDownloadedDate
        credlyAcceptedDate
        deleted
        evidenceWorkbookUrl
        evidenceWworksheetUrl
        evidenceNote
        ivo
        owners
        updatedAt
        __typename
      }
      totalStep
      duration
      purchasedDate
      source
      standard
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const onCreateCertificationProgress = /* GraphQL */ `
  subscription OnCreateCertificationProgress(
    $filter: ModelSubscriptionCertificationProgressFilterInput
  ) {
    onCreateCertificationProgress(filter: $filter) {
      id
      level
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      status
      role
      title
      firstName
      lastName
      email
      auditEmail
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          ccid
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            title
            description
            notApplicable
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationSignedAt
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      ivo
      owners
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCertificationProgress = /* GraphQL */ `
  subscription OnUpdateCertificationProgress(
    $filter: ModelSubscriptionCertificationProgressFilterInput
  ) {
    onUpdateCertificationProgress(filter: $filter) {
      id
      level
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      status
      role
      title
      firstName
      lastName
      email
      auditEmail
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          ccid
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            title
            description
            notApplicable
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationSignedAt
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      ivo
      owners
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCertificationProgress = /* GraphQL */ `
  subscription OnDeleteCertificationProgress(
    $filter: ModelSubscriptionCertificationProgressFilterInput
  ) {
    onDeleteCertificationProgress(filter: $filter) {
      id
      level
      self
      createdAt
      certID
      ABN
      ccid
      orgId
      status
      role
      title
      firstName
      lastName
      email
      auditEmail
      mobile
      requirements {
        items {
          id
          progressId
          requirementId
          ABN
          ccid
          orgId
          self
          createdAt
          attestationStatus
          attestationNote
          attestedBy
          notApplicableDetails
          detail {
            requirementId
            baseId
            title
            description
            notApplicable
            __typename
          }
          assignedToITSpecialist
          deleted
          owners
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      step
      envelopeId
      attestationResendTime
      attestationSigned
      attestationSignedAt
      attestationReviewed
      dateLodged
      confetti
      expiredDate
      issuedDate
      completedDate
      workbookDownloadedDate
      excelDownloadedDate
      credlyAcceptedDate
      deleted
      evidenceWorkbookUrl
      evidenceWworksheetUrl
      evidenceNote
      ivo
      owners
      updatedAt
      __typename
    }
  }
`;
export const onCreateRequirement = /* GraphQL */ `
  subscription OnCreateRequirement(
    $filter: ModelSubscriptionRequirementFilterInput
  ) {
    onCreateRequirement(filter: $filter) {
      id
      progressId
      requirementId
      ABN
      ccid
      orgId
      self
      createdAt
      attestationStatus
      attestationNote
      attestedBy
      notApplicableDetails
      detail {
        requirementId
        baseId
        title
        description
        notApplicable
        __typename
      }
      assignedToITSpecialist
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRequirement = /* GraphQL */ `
  subscription OnUpdateRequirement(
    $filter: ModelSubscriptionRequirementFilterInput
  ) {
    onUpdateRequirement(filter: $filter) {
      id
      progressId
      requirementId
      ABN
      ccid
      orgId
      self
      createdAt
      attestationStatus
      attestationNote
      attestedBy
      notApplicableDetails
      detail {
        requirementId
        baseId
        title
        description
        notApplicable
        __typename
      }
      assignedToITSpecialist
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRequirement = /* GraphQL */ `
  subscription OnDeleteRequirement(
    $filter: ModelSubscriptionRequirementFilterInput
  ) {
    onDeleteRequirement(filter: $filter) {
      id
      progressId
      requirementId
      ABN
      ccid
      orgId
      self
      createdAt
      attestationStatus
      attestationNote
      attestedBy
      notApplicableDetails
      detail {
        requirementId
        baseId
        title
        description
        notApplicable
        __typename
      }
      assignedToITSpecialist
      deleted
      owners
      updatedAt
      __typename
    }
  }
`;
export const onCreateDiscount = /* GraphQL */ `
  subscription OnCreateDiscount($filter: ModelSubscriptionDiscountFilterInput) {
    onCreateDiscount(filter: $filter) {
      id
      customer
      ruleId
      rule {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      code
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDiscount = /* GraphQL */ `
  subscription OnUpdateDiscount($filter: ModelSubscriptionDiscountFilterInput) {
    onUpdateDiscount(filter: $filter) {
      id
      customer
      ruleId
      rule {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      code
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDiscount = /* GraphQL */ `
  subscription OnDeleteDiscount($filter: ModelSubscriptionDiscountFilterInput) {
    onDeleteDiscount(filter: $filter) {
      id
      customer
      ruleId
      rule {
        id
        type
        rate
        levels
        applyType
        self
        createdAt
        deleted
        updatedAt
        __typename
      }
      code
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateCommission = /* GraphQL */ `
  subscription OnCreateCommission(
    $filter: ModelSubscriptionCommissionFilterInput
  ) {
    onCreateCommission(filter: $filter) {
      id
      partner
      saleId
      ruleId
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCommission = /* GraphQL */ `
  subscription OnUpdateCommission(
    $filter: ModelSubscriptionCommissionFilterInput
  ) {
    onUpdateCommission(filter: $filter) {
      id
      partner
      saleId
      ruleId
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCommission = /* GraphQL */ `
  subscription OnDeleteCommission(
    $filter: ModelSubscriptionCommissionFilterInput
  ) {
    onDeleteCommission(filter: $filter) {
      id
      partner
      saleId
      ruleId
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateRule = /* GraphQL */ `
  subscription OnCreateRule($filter: ModelSubscriptionRuleFilterInput) {
    onCreateRule(filter: $filter) {
      id
      type
      rate
      levels
      applyType
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRule = /* GraphQL */ `
  subscription OnUpdateRule($filter: ModelSubscriptionRuleFilterInput) {
    onUpdateRule(filter: $filter) {
      id
      type
      rate
      levels
      applyType
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRule = /* GraphQL */ `
  subscription OnDeleteRule($filter: ModelSubscriptionRuleFilterInput) {
    onDeleteRule(filter: $filter) {
      id
      type
      rate
      levels
      applyType
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateApplyRule = /* GraphQL */ `
  subscription OnCreateApplyRule(
    $filter: ModelSubscriptionApplyRuleFilterInput
  ) {
    onCreateApplyRule(filter: $filter) {
      id
      partnerRuleId
      partner
      startDate
      endDate
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateApplyRule = /* GraphQL */ `
  subscription OnUpdateApplyRule(
    $filter: ModelSubscriptionApplyRuleFilterInput
  ) {
    onUpdateApplyRule(filter: $filter) {
      id
      partnerRuleId
      partner
      startDate
      endDate
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteApplyRule = /* GraphQL */ `
  subscription OnDeleteApplyRule(
    $filter: ModelSubscriptionApplyRuleFilterInput
  ) {
    onDeleteApplyRule(filter: $filter) {
      id
      partnerRuleId
      partner
      startDate
      endDate
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreatePartnerRule = /* GraphQL */ `
  subscription OnCreatePartnerRule(
    $filter: ModelSubscriptionPartnerRuleFilterInput
  ) {
    onCreatePartnerRule(filter: $filter) {
      id
      name
      users
      associatedRules
      globalRules
      recruitCommission
      accessRules
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartnerRule = /* GraphQL */ `
  subscription OnUpdatePartnerRule(
    $filter: ModelSubscriptionPartnerRuleFilterInput
  ) {
    onUpdatePartnerRule(filter: $filter) {
      id
      name
      users
      associatedRules
      globalRules
      recruitCommission
      accessRules
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartnerRule = /* GraphQL */ `
  subscription OnDeletePartnerRule(
    $filter: ModelSubscriptionPartnerRuleFilterInput
  ) {
    onDeletePartnerRule(filter: $filter) {
      id
      name
      users
      associatedRules
      globalRules
      recruitCommission
      accessRules
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateAccessRule = /* GraphQL */ `
  subscription OnCreateAccessRule(
    $filter: ModelSubscriptionAccessRuleFilterInput
  ) {
    onCreateAccessRule(filter: $filter) {
      id
      target
      permissions
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAccessRule = /* GraphQL */ `
  subscription OnUpdateAccessRule(
    $filter: ModelSubscriptionAccessRuleFilterInput
  ) {
    onUpdateAccessRule(filter: $filter) {
      id
      target
      permissions
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAccessRule = /* GraphQL */ `
  subscription OnDeleteAccessRule(
    $filter: ModelSubscriptionAccessRuleFilterInput
  ) {
    onDeleteAccessRule(filter: $filter) {
      id
      target
      permissions
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateAuditRequest = /* GraphQL */ `
  subscription OnCreateAuditRequest(
    $filter: ModelSubscriptionAuditRequestFilterInput
  ) {
    onCreateAuditRequest(filter: $filter) {
      id
      ABN
      ccid
      orgId
      certificationId
      level
      attempted
      datePaid
      status
      reviewer
      reviewerABN
      reviewerCCID
      reviewerOrgId
      reviewNotes
      ivo
      acknowledged
      acknowledgedBy
      acknowledgedAt
      auditStartedAt
      auditCompletedAt
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAuditRequest = /* GraphQL */ `
  subscription OnUpdateAuditRequest(
    $filter: ModelSubscriptionAuditRequestFilterInput
  ) {
    onUpdateAuditRequest(filter: $filter) {
      id
      ABN
      ccid
      orgId
      certificationId
      level
      attempted
      datePaid
      status
      reviewer
      reviewerABN
      reviewerCCID
      reviewerOrgId
      reviewNotes
      ivo
      acknowledged
      acknowledgedBy
      acknowledgedAt
      auditStartedAt
      auditCompletedAt
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAuditRequest = /* GraphQL */ `
  subscription OnDeleteAuditRequest(
    $filter: ModelSubscriptionAuditRequestFilterInput
  ) {
    onDeleteAuditRequest(filter: $filter) {
      id
      ABN
      ccid
      orgId
      certificationId
      level
      attempted
      datePaid
      status
      reviewer
      reviewerABN
      reviewerCCID
      reviewerOrgId
      reviewNotes
      ivo
      acknowledged
      acknowledgedBy
      acknowledgedAt
      auditStartedAt
      auditCompletedAt
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateActivityFeed = /* GraphQL */ `
  subscription OnCreateActivityFeed(
    $filter: ModelSubscriptionActivityFeedFilterInput
  ) {
    onCreateActivityFeed(filter: $filter) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      type
      name
      level
      standard
      email
      authoriser
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateActivityFeed = /* GraphQL */ `
  subscription OnUpdateActivityFeed(
    $filter: ModelSubscriptionActivityFeedFilterInput
  ) {
    onUpdateActivityFeed(filter: $filter) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      type
      name
      level
      standard
      email
      authoriser
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteActivityFeed = /* GraphQL */ `
  subscription OnDeleteActivityFeed(
    $filter: ModelSubscriptionActivityFeedFilterInput
  ) {
    onDeleteActivityFeed(filter: $filter) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      type
      name
      level
      standard
      email
      authoriser
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      status
      type
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      status
      type
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      ABN
      ccid
      orgId
      self
      createdAt
      status
      type
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateSale = /* GraphQL */ `
  subscription OnCreateSale($filter: ModelSubscriptionSaleFilterInput) {
    onCreateSale(filter: $filter) {
      id
      ABN
      ccid
      orgId
      email
      item
      certID
      status
      discountRate
      amountPaid
      datePaid
      type
      public
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSale = /* GraphQL */ `
  subscription OnUpdateSale($filter: ModelSubscriptionSaleFilterInput) {
    onUpdateSale(filter: $filter) {
      id
      ABN
      ccid
      orgId
      email
      item
      certID
      status
      discountRate
      amountPaid
      datePaid
      type
      public
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSale = /* GraphQL */ `
  subscription OnDeleteSale($filter: ModelSubscriptionSaleFilterInput) {
    onDeleteSale(filter: $filter) {
      id
      ABN
      ccid
      orgId
      email
      item
      certID
      status
      discountRate
      amountPaid
      datePaid
      type
      public
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
    onCreateReport(filter: $filter) {
      id
      name
      status
      totalReceivers
      success
      fail
      month
      year
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
    onUpdateReport(filter: $filter) {
      id
      name
      status
      totalReceivers
      success
      fail
      month
      year
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
    onDeleteReport(filter: $filter) {
      id
      name
      status
      totalReceivers
      success
      fail
      month
      year
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreatePartnerReport = /* GraphQL */ `
  subscription OnCreatePartnerReport(
    $filter: ModelSubscriptionPartnerReportFilterInput
  ) {
    onCreatePartnerReport(filter: $filter) {
      id
      partner
      reportId
      reportDoc
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartnerReport = /* GraphQL */ `
  subscription OnUpdatePartnerReport(
    $filter: ModelSubscriptionPartnerReportFilterInput
  ) {
    onUpdatePartnerReport(filter: $filter) {
      id
      partner
      reportId
      reportDoc
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartnerReport = /* GraphQL */ `
  subscription OnDeletePartnerReport(
    $filter: ModelSubscriptionPartnerReportFilterInput
  ) {
    onDeletePartnerReport(filter: $filter) {
      id
      partner
      reportId
      reportDoc
      self
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateRecommendationTool = /* GraphQL */ `
  subscription OnCreateRecommendationTool(
    $filter: ModelSubscriptionRecommendationToolFilterInput
    $owner: String
  ) {
    onCreateRecommendationTool(filter: $filter, owner: $owner) {
      id
      result
      progress {
        id
        value
        __typename
      }
      ABN
      ccid
      orgId
      eligibleRequirements
      owners
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateRecommendationTool = /* GraphQL */ `
  subscription OnUpdateRecommendationTool(
    $filter: ModelSubscriptionRecommendationToolFilterInput
    $owner: String
  ) {
    onUpdateRecommendationTool(filter: $filter, owner: $owner) {
      id
      result
      progress {
        id
        value
        __typename
      }
      ABN
      ccid
      orgId
      eligibleRequirements
      owners
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteRecommendationTool = /* GraphQL */ `
  subscription OnDeleteRecommendationTool(
    $filter: ModelSubscriptionRecommendationToolFilterInput
    $owner: String
  ) {
    onDeleteRecommendationTool(filter: $filter, owner: $owner) {
      id
      result
      progress {
        id
        value
        __typename
      }
      ABN
      ccid
      orgId
      eligibleRequirements
      owners
      deleted
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateClaimCode = /* GraphQL */ `
  subscription OnCreateClaimCode(
    $filter: ModelSubscriptionClaimCodeFilterInput
  ) {
    onCreateClaimCode(filter: $filter) {
      id
      code
      expired
      ABN
      ccid
      orgId
      level
      partnerName
      name
      logo
      pageTitle
      description
      dashboardType
      tally
      totalTally
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateClaimCode = /* GraphQL */ `
  subscription OnUpdateClaimCode(
    $filter: ModelSubscriptionClaimCodeFilterInput
  ) {
    onUpdateClaimCode(filter: $filter) {
      id
      code
      expired
      ABN
      ccid
      orgId
      level
      partnerName
      name
      logo
      pageTitle
      description
      dashboardType
      tally
      totalTally
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteClaimCode = /* GraphQL */ `
  subscription OnDeleteClaimCode(
    $filter: ModelSubscriptionClaimCodeFilterInput
  ) {
    onDeleteClaimCode(filter: $filter) {
      id
      code
      expired
      ABN
      ccid
      orgId
      level
      partnerName
      name
      logo
      pageTitle
      description
      dashboardType
      tally
      totalTally
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCheckout = /* GraphQL */ `
  subscription OnCreateCheckout($filter: ModelSubscriptionCheckoutFilterInput) {
    onCreateCheckout(filter: $filter) {
      id
      sessionId
      ABN
      ccid
      orgId
      country
      item
      status
      source
      userId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCheckout = /* GraphQL */ `
  subscription OnUpdateCheckout($filter: ModelSubscriptionCheckoutFilterInput) {
    onUpdateCheckout(filter: $filter) {
      id
      sessionId
      ABN
      ccid
      orgId
      country
      item
      status
      source
      userId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCheckout = /* GraphQL */ `
  subscription OnDeleteCheckout($filter: ModelSubscriptionCheckoutFilterInput) {
    onDeleteCheckout(filter: $filter) {
      id
      sessionId
      ABN
      ccid
      orgId
      country
      item
      status
      source
      userId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSMSChallenge = /* GraphQL */ `
  subscription OnCreateSMSChallenge(
    $filter: ModelSubscriptionSMSChallengeFilterInput
  ) {
    onCreateSMSChallenge(filter: $filter) {
      id
      phone
      code
      userId
      codeExpiredAt
      codeResendAllowedAt
      status
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSMSChallenge = /* GraphQL */ `
  subscription OnUpdateSMSChallenge(
    $filter: ModelSubscriptionSMSChallengeFilterInput
  ) {
    onUpdateSMSChallenge(filter: $filter) {
      id
      phone
      code
      userId
      codeExpiredAt
      codeResendAllowedAt
      status
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSMSChallenge = /* GraphQL */ `
  subscription OnDeleteSMSChallenge(
    $filter: ModelSubscriptionSMSChallengeFilterInput
  ) {
    onDeleteSMSChallenge(filter: $filter) {
      id
      phone
      code
      userId
      codeExpiredAt
      codeResendAllowedAt
      status
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePartnerInvite = /* GraphQL */ `
  subscription OnCreatePartnerInvite(
    $filter: ModelSubscriptionPartnerInviteFilterInput
  ) {
    onCreatePartnerInvite(filter: $filter) {
      id
      partnerABN
      partnerCCID
      partnerOrgId
      partnerName
      ABN
      ccid
      orgId
      orgCountry
      level
      dashboardType
      status
      specialProgram
      standard
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartnerInvite = /* GraphQL */ `
  subscription OnUpdatePartnerInvite(
    $filter: ModelSubscriptionPartnerInviteFilterInput
  ) {
    onUpdatePartnerInvite(filter: $filter) {
      id
      partnerABN
      partnerCCID
      partnerOrgId
      partnerName
      ABN
      ccid
      orgId
      orgCountry
      level
      dashboardType
      status
      specialProgram
      standard
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartnerInvite = /* GraphQL */ `
  subscription OnDeletePartnerInvite(
    $filter: ModelSubscriptionPartnerInviteFilterInput
  ) {
    onDeletePartnerInvite(filter: $filter) {
      id
      partnerABN
      partnerCCID
      partnerOrgId
      partnerName
      ABN
      ccid
      orgId
      orgCountry
      level
      dashboardType
      status
      specialProgram
      standard
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePartnerOffer = /* GraphQL */ `
  subscription OnCreatePartnerOffer(
    $filter: ModelSubscriptionPartnerOfferFilterInput
  ) {
    onCreatePartnerOffer(filter: $filter) {
      id
      ABN
      ccid
      orgId
      discount
      dashboard
      expiry
      isActive
      isOneTimeOffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartnerOffer = /* GraphQL */ `
  subscription OnUpdatePartnerOffer(
    $filter: ModelSubscriptionPartnerOfferFilterInput
  ) {
    onUpdatePartnerOffer(filter: $filter) {
      id
      ABN
      ccid
      orgId
      discount
      dashboard
      expiry
      isActive
      isOneTimeOffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartnerOffer = /* GraphQL */ `
  subscription OnDeletePartnerOffer(
    $filter: ModelSubscriptionPartnerOfferFilterInput
  ) {
    onDeletePartnerOffer(filter: $filter) {
      id
      ABN
      ccid
      orgId
      discount
      dashboard
      expiry
      isActive
      isOneTimeOffer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePartnerOfferAccept = /* GraphQL */ `
  subscription OnCreatePartnerOfferAccept(
    $filter: ModelSubscriptionPartnerOfferAcceptFilterInput
  ) {
    onCreatePartnerOfferAccept(filter: $filter) {
      id
      offerId
      standard
      ABN
      ccid
      orgId
      orgCountry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePartnerOfferAccept = /* GraphQL */ `
  subscription OnUpdatePartnerOfferAccept(
    $filter: ModelSubscriptionPartnerOfferAcceptFilterInput
  ) {
    onUpdatePartnerOfferAccept(filter: $filter) {
      id
      offerId
      standard
      ABN
      ccid
      orgId
      orgCountry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePartnerOfferAccept = /* GraphQL */ `
  subscription OnDeletePartnerOfferAccept(
    $filter: ModelSubscriptionPartnerOfferAcceptFilterInput
  ) {
    onDeletePartnerOfferAccept(filter: $filter) {
      id
      offerId
      standard
      ABN
      ccid
      orgId
      orgCountry
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssessment = /* GraphQL */ `
  subscription OnCreateAssessment(
    $filter: ModelSubscriptionAssessmentFilterInput
  ) {
    onCreateAssessment(filter: $filter) {
      id
      type
      token
      ABN
      ccid
      orgId
      questionnaire {
        id
        requirement
        question
        __typename
      }
      maximumLevel
      dashboard
      isActive
      isSetup
      activeAt
      submitedAt
      standard
      archived
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssessment = /* GraphQL */ `
  subscription OnUpdateAssessment(
    $filter: ModelSubscriptionAssessmentFilterInput
  ) {
    onUpdateAssessment(filter: $filter) {
      id
      type
      token
      ABN
      ccid
      orgId
      questionnaire {
        id
        requirement
        question
        __typename
      }
      maximumLevel
      dashboard
      isActive
      isSetup
      activeAt
      submitedAt
      standard
      archived
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssessment = /* GraphQL */ `
  subscription OnDeleteAssessment(
    $filter: ModelSubscriptionAssessmentFilterInput
  ) {
    onDeleteAssessment(filter: $filter) {
      id
      type
      token
      ABN
      ccid
      orgId
      questionnaire {
        id
        requirement
        question
        __typename
      }
      maximumLevel
      dashboard
      isActive
      isSetup
      activeAt
      submitedAt
      standard
      archived
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssessmentResult = /* GraphQL */ `
  subscription OnCreateAssessmentResult(
    $filter: ModelSubscriptionAssessmentResultFilterInput
  ) {
    onCreateAssessmentResult(filter: $filter) {
      id
      type
      assessmentId
      assessmentToken
      ABN
      ccid
      orgId
      name
      result
      answers {
        id
        requirement
        value
        __typename
      }
      implementedRequirements
      completedBy
      session
      verifiedVia
      verifiedData
      verifiedAt
      completedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssessmentResult = /* GraphQL */ `
  subscription OnUpdateAssessmentResult(
    $filter: ModelSubscriptionAssessmentResultFilterInput
  ) {
    onUpdateAssessmentResult(filter: $filter) {
      id
      type
      assessmentId
      assessmentToken
      ABN
      ccid
      orgId
      name
      result
      answers {
        id
        requirement
        value
        __typename
      }
      implementedRequirements
      completedBy
      session
      verifiedVia
      verifiedData
      verifiedAt
      completedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssessmentResult = /* GraphQL */ `
  subscription OnDeleteAssessmentResult(
    $filter: ModelSubscriptionAssessmentResultFilterInput
  ) {
    onDeleteAssessmentResult(filter: $filter) {
      id
      type
      assessmentId
      assessmentToken
      ABN
      ccid
      orgId
      name
      result
      answers {
        id
        requirement
        value
        __typename
      }
      implementedRequirements
      completedBy
      session
      verifiedVia
      verifiedData
      verifiedAt
      completedAt
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSpecialOffer = /* GraphQL */ `
  subscription OnCreateSpecialOffer(
    $filter: ModelSubscriptionSpecialOfferFilterInput
  ) {
    onCreateSpecialOffer(filter: $filter) {
      id
      name
      isActive
      expiryAccActive
      expiryDays
      goldGrant
      silverGrant
      bronzeGrant
      banner
      logo
      hero
      grantedLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSpecialOffer = /* GraphQL */ `
  subscription OnUpdateSpecialOffer(
    $filter: ModelSubscriptionSpecialOfferFilterInput
  ) {
    onUpdateSpecialOffer(filter: $filter) {
      id
      name
      isActive
      expiryAccActive
      expiryDays
      goldGrant
      silverGrant
      bronzeGrant
      banner
      logo
      hero
      grantedLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSpecialOffer = /* GraphQL */ `
  subscription OnDeleteSpecialOffer(
    $filter: ModelSubscriptionSpecialOfferFilterInput
  ) {
    onDeleteSpecialOffer(filter: $filter) {
      id
      name
      isActive
      expiryAccActive
      expiryDays
      goldGrant
      silverGrant
      bronzeGrant
      banner
      logo
      hero
      grantedLevel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSpecialOfferAccept = /* GraphQL */ `
  subscription OnCreateSpecialOfferAccept(
    $filter: ModelSubscriptionSpecialOfferAcceptFilterInput
  ) {
    onCreateSpecialOfferAccept(filter: $filter) {
      id
      offerId
      offerName
      ABN
      ccid
      orgId
      status
      dashboard
      firstEmailActive
      firstEmailReminder
      firstEmailTemplate
      secondEmailActive
      secondEmailReminder
      secondEmailTemplate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSpecialOfferAccept = /* GraphQL */ `
  subscription OnUpdateSpecialOfferAccept(
    $filter: ModelSubscriptionSpecialOfferAcceptFilterInput
  ) {
    onUpdateSpecialOfferAccept(filter: $filter) {
      id
      offerId
      offerName
      ABN
      ccid
      orgId
      status
      dashboard
      firstEmailActive
      firstEmailReminder
      firstEmailTemplate
      secondEmailActive
      secondEmailReminder
      secondEmailTemplate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSpecialOfferAccept = /* GraphQL */ `
  subscription OnDeleteSpecialOfferAccept(
    $filter: ModelSubscriptionSpecialOfferAcceptFilterInput
  ) {
    onDeleteSpecialOfferAccept(filter: $filter) {
      id
      offerId
      offerName
      ABN
      ccid
      orgId
      status
      dashboard
      firstEmailActive
      firstEmailReminder
      firstEmailTemplate
      secondEmailActive
      secondEmailReminder
      secondEmailTemplate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCAPReferral = /* GraphQL */ `
  subscription OnCreateCAPReferral(
    $filter: ModelSubscriptionCAPReferralFilterInput
  ) {
    onCreateCAPReferral(filter: $filter) {
      code
      self
      partnerABN
      partnerCCID
      partnerOrgId
      email
      linkToReferrer
      firstName
      lastName
      isActive
      certBundle
      dashboard
      referralPartners
      specialOffer
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCAPReferral = /* GraphQL */ `
  subscription OnUpdateCAPReferral(
    $filter: ModelSubscriptionCAPReferralFilterInput
  ) {
    onUpdateCAPReferral(filter: $filter) {
      code
      self
      partnerABN
      partnerCCID
      partnerOrgId
      email
      linkToReferrer
      firstName
      lastName
      isActive
      certBundle
      dashboard
      referralPartners
      specialOffer
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCAPReferral = /* GraphQL */ `
  subscription OnDeleteCAPReferral(
    $filter: ModelSubscriptionCAPReferralFilterInput
  ) {
    onDeleteCAPReferral(filter: $filter) {
      code
      self
      partnerABN
      partnerCCID
      partnerOrgId
      email
      linkToReferrer
      firstName
      lastName
      isActive
      certBundle
      dashboard
      referralPartners
      specialOffer
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateCertBundle = /* GraphQL */ `
  subscription OnCreateCertBundle(
    $filter: ModelSubscriptionCertBundleFilterInput
  ) {
    onCreateCertBundle(filter: $filter) {
      id
      self
      bundleName
      bronzeQTY
      silverQTY
      goldQTY
      platinumQTY
      diamondQTY
      isActive
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCertBundle = /* GraphQL */ `
  subscription OnUpdateCertBundle(
    $filter: ModelSubscriptionCertBundleFilterInput
  ) {
    onUpdateCertBundle(filter: $filter) {
      id
      self
      bundleName
      bronzeQTY
      silverQTY
      goldQTY
      platinumQTY
      diamondQTY
      isActive
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCertBundle = /* GraphQL */ `
  subscription OnDeleteCertBundle(
    $filter: ModelSubscriptionCertBundleFilterInput
  ) {
    onDeleteCertBundle(filter: $filter) {
      id
      self
      bundleName
      bronzeQTY
      silverQTY
      goldQTY
      platinumQTY
      diamondQTY
      isActive
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreatePendingPartner = /* GraphQL */ `
  subscription OnCreatePendingPartner(
    $filter: ModelSubscriptionPendingPartnerFilterInput
  ) {
    onCreatePendingPartner(filter: $filter) {
      ABN
      ccid
      orgId
      govId
      orgCountry
      location
      entityType
      self
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      initialDashboardType
      name
      addedAs
      industry
      type
      ACN
      ANZIC
      orgPhone
      website
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      firstName
      lastName
      email
      workPhone
      phone
      role
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      commencementDate
      annualPartnerFee
      subscriptionDiscount
      codeExpiry
      minimumVolume
      volumeDiscount
      discount
      referredBy
      certBundle
      isSubmissionDone
      isPartnerSigned
      partnerSignedAt
      isAdminSigned
      adminSignedAt
      signingSession
      envelopeId
      envelopeSentAt
      isOnboarded
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePendingPartner = /* GraphQL */ `
  subscription OnUpdatePendingPartner(
    $filter: ModelSubscriptionPendingPartnerFilterInput
  ) {
    onUpdatePendingPartner(filter: $filter) {
      ABN
      ccid
      orgId
      govId
      orgCountry
      location
      entityType
      self
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      initialDashboardType
      name
      addedAs
      industry
      type
      ACN
      ANZIC
      orgPhone
      website
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      firstName
      lastName
      email
      workPhone
      phone
      role
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      commencementDate
      annualPartnerFee
      subscriptionDiscount
      codeExpiry
      minimumVolume
      volumeDiscount
      discount
      referredBy
      certBundle
      isSubmissionDone
      isPartnerSigned
      partnerSignedAt
      isAdminSigned
      adminSignedAt
      signingSession
      envelopeId
      envelopeSentAt
      isOnboarded
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeletePendingPartner = /* GraphQL */ `
  subscription OnDeletePendingPartner(
    $filter: ModelSubscriptionPendingPartnerFilterInput
  ) {
    onDeletePendingPartner(filter: $filter) {
      ABN
      ccid
      orgId
      govId
      orgCountry
      location
      entityType
      self
      isTypeCAP
      isTypeReferrer
      isTypeMSP
      isTypeConsultant
      isTypeSupplyChain
      isTypeMembership
      isTypeFranchise
      isTypeVendor
      initialDashboardType
      name
      addedAs
      industry
      type
      ACN
      ANZIC
      orgPhone
      website
      numEmployees
      HOaddressCountry
      HOaddressStreet
      HOaddressSuburb
      HOaddressState
      HOaddressPostcode
      POaddressCountry
      POaddressStreet
      POaddressSuburb
      POaddressState
      POaddressPostcode
      firstName
      lastName
      email
      workPhone
      phone
      role
      RMFirstName
      RMLastName
      RMEmail
      RMPhone
      BDMFirstName
      BDMLastName
      BDMEmail
      BDMPhone
      RANaddressFirstName
      RANaddressLastName
      RANaddressEmail
      RANaddressPhone
      RANaddressRole
      RANaddressStreet
      RANaddressSuburb
      RANaddressState
      RANaddressPostcode
      RANaddressCountry
      commencementDate
      annualPartnerFee
      subscriptionDiscount
      codeExpiry
      minimumVolume
      volumeDiscount
      discount
      referredBy
      certBundle
      isSubmissionDone
      isPartnerSigned
      partnerSignedAt
      isAdminSigned
      adminSignedAt
      signingSession
      envelopeId
      envelopeSentAt
      isOnboarded
      createdAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserInvite = /* GraphQL */ `
  subscription OnCreateUserInvite(
    $filter: ModelSubscriptionUserInviteFilterInput
  ) {
    onCreateUserInvite(filter: $filter) {
      id
      userId
      email
      phone
      fullName
      firstName
      lastName
      role
      status
      ABN
      ccid
      orgId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserInvite = /* GraphQL */ `
  subscription OnUpdateUserInvite(
    $filter: ModelSubscriptionUserInviteFilterInput
  ) {
    onUpdateUserInvite(filter: $filter) {
      id
      userId
      email
      phone
      fullName
      firstName
      lastName
      role
      status
      ABN
      ccid
      orgId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserInvite = /* GraphQL */ `
  subscription OnDeleteUserInvite(
    $filter: ModelSubscriptionUserInviteFilterInput
  ) {
    onDeleteUserInvite(filter: $filter) {
      id
      userId
      email
      phone
      fullName
      firstName
      lastName
      role
      status
      ABN
      ccid
      orgId
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRegion = /* GraphQL */ `
  subscription OnCreateRegion($filter: ModelSubscriptionRegionFilterInput) {
    onCreateRegion(filter: $filter) {
      countryCode
      countryName
      description
      supportedLanguages
      localePaths
      discount
      discountAppliedLevels
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRegion = /* GraphQL */ `
  subscription OnUpdateRegion($filter: ModelSubscriptionRegionFilterInput) {
    onUpdateRegion(filter: $filter) {
      countryCode
      countryName
      description
      supportedLanguages
      localePaths
      discount
      discountAppliedLevels
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRegion = /* GraphQL */ `
  subscription OnDeleteRegion($filter: ModelSubscriptionRegionFilterInput) {
    onDeleteRegion(filter: $filter) {
      countryCode
      countryName
      description
      supportedLanguages
      localePaths
      discount
      discountAppliedLevels
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGovID = /* GraphQL */ `
  subscription OnCreateGovID($filter: ModelSubscriptionGovIDFilterInput) {
    onCreateGovID(filter: $filter) {
      countryCode
      desctipion
      identifiers
      format
      idName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGovID = /* GraphQL */ `
  subscription OnUpdateGovID($filter: ModelSubscriptionGovIDFilterInput) {
    onUpdateGovID(filter: $filter) {
      countryCode
      desctipion
      identifiers
      format
      idName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGovID = /* GraphQL */ `
  subscription OnDeleteGovID($filter: ModelSubscriptionGovIDFilterInput) {
    onDeleteGovID(filter: $filter) {
      countryCode
      desctipion
      identifiers
      format
      idName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePendingExternalSpecialist = /* GraphQL */ `
  subscription OnCreatePendingExternalSpecialist(
    $filter: ModelSubscriptionPendingExternalSpecialistFilterInput
  ) {
    onCreatePendingExternalSpecialist(filter: $filter) {
      id
      ABN
      orgId
      ccid
      name
      tradingName
      person
      email
      phone
      website
      addedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePendingExternalSpecialist = /* GraphQL */ `
  subscription OnUpdatePendingExternalSpecialist(
    $filter: ModelSubscriptionPendingExternalSpecialistFilterInput
  ) {
    onUpdatePendingExternalSpecialist(filter: $filter) {
      id
      ABN
      orgId
      ccid
      name
      tradingName
      person
      email
      phone
      website
      addedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePendingExternalSpecialist = /* GraphQL */ `
  subscription OnDeletePendingExternalSpecialist(
    $filter: ModelSubscriptionPendingExternalSpecialistFilterInput
  ) {
    onDeletePendingExternalSpecialist(filter: $filter) {
      id
      ABN
      orgId
      ccid
      name
      tradingName
      person
      email
      phone
      website
      addedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCertificationSpecialistEngagement = /* GraphQL */ `
  subscription OnCreateCertificationSpecialistEngagement(
    $filter: ModelSubscriptionCertificationSpecialistEngagementFilterInput
  ) {
    onCreateCertificationSpecialistEngagement(filter: $filter) {
      id
      ABN
      orgId
      ccid
      certificationId
      specialistABN
      specialistGovID
      status
      region
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCertificationSpecialistEngagement = /* GraphQL */ `
  subscription OnUpdateCertificationSpecialistEngagement(
    $filter: ModelSubscriptionCertificationSpecialistEngagementFilterInput
  ) {
    onUpdateCertificationSpecialistEngagement(filter: $filter) {
      id
      ABN
      orgId
      ccid
      certificationId
      specialistABN
      specialistGovID
      status
      region
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCertificationSpecialistEngagement = /* GraphQL */ `
  subscription OnDeleteCertificationSpecialistEngagement(
    $filter: ModelSubscriptionCertificationSpecialistEngagementFilterInput
  ) {
    onDeleteCertificationSpecialistEngagement(filter: $filter) {
      id
      ABN
      orgId
      ccid
      certificationId
      specialistABN
      specialistGovID
      status
      region
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEOIRegistration = /* GraphQL */ `
  subscription OnCreateEOIRegistration(
    $filter: ModelSubscriptionEOIRegistrationFilterInput
  ) {
    onCreateEOIRegistration(filter: $filter) {
      id
      countryCode
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEOIRegistration = /* GraphQL */ `
  subscription OnUpdateEOIRegistration(
    $filter: ModelSubscriptionEOIRegistrationFilterInput
  ) {
    onUpdateEOIRegistration(filter: $filter) {
      id
      countryCode
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEOIRegistration = /* GraphQL */ `
  subscription OnDeleteEOIRegistration(
    $filter: ModelSubscriptionEOIRegistrationFilterInput
  ) {
    onDeleteEOIRegistration(filter: $filter) {
      id
      countryCode
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSMBSurvey = /* GraphQL */ `
  subscription OnCreateSMBSurvey(
    $filter: ModelSubscriptionSMBSurveyFilterInput
  ) {
    onCreateSMBSurvey(filter: $filter) {
      id
      email
      firstName
      lastName
      region
      submitedAt
      results
      surveyReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSMBSurvey = /* GraphQL */ `
  subscription OnUpdateSMBSurvey(
    $filter: ModelSubscriptionSMBSurveyFilterInput
  ) {
    onUpdateSMBSurvey(filter: $filter) {
      id
      email
      firstName
      lastName
      region
      submitedAt
      results
      surveyReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSMBSurvey = /* GraphQL */ `
  subscription OnDeleteSMBSurvey(
    $filter: ModelSubscriptionSMBSurveyFilterInput
  ) {
    onDeleteSMBSurvey(filter: $filter) {
      id
      email
      firstName
      lastName
      region
      submitedAt
      results
      surveyReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSMBSurveyDetail = /* GraphQL */ `
  subscription OnCreateSMBSurveyDetail(
    $filter: ModelSubscriptionSMBSurveyDetailFilterInput
  ) {
    onCreateSMBSurveyDetail(filter: $filter) {
      id
      surveyId
      questionId
      response
      recommendation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSMBSurveyDetail = /* GraphQL */ `
  subscription OnUpdateSMBSurveyDetail(
    $filter: ModelSubscriptionSMBSurveyDetailFilterInput
  ) {
    onUpdateSMBSurveyDetail(filter: $filter) {
      id
      surveyId
      questionId
      response
      recommendation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSMBSurveyDetail = /* GraphQL */ `
  subscription OnDeleteSMBSurveyDetail(
    $filter: ModelSubscriptionSMBSurveyDetailFilterInput
  ) {
    onDeleteSMBSurveyDetail(filter: $filter) {
      id
      surveyId
      questionId
      response
      recommendation
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSMBLead = /* GraphQL */ `
  subscription OnCreateSMBLead($filter: ModelSubscriptionSMBLeadFilterInput) {
    onCreateSMBLead(filter: $filter) {
      id
      firstName
      lastName
      email
      phone
      region
      companyName
      inboundMethod
      refCode
      isPartner
      partnerEOI
      surveyEOI
      quizCompleted
      hasPurchased
      purchaseEOI
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSMBLead = /* GraphQL */ `
  subscription OnUpdateSMBLead($filter: ModelSubscriptionSMBLeadFilterInput) {
    onUpdateSMBLead(filter: $filter) {
      id
      firstName
      lastName
      email
      phone
      region
      companyName
      inboundMethod
      refCode
      isPartner
      partnerEOI
      surveyEOI
      quizCompleted
      hasPurchased
      purchaseEOI
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSMBLead = /* GraphQL */ `
  subscription OnDeleteSMBLead($filter: ModelSubscriptionSMBLeadFilterInput) {
    onDeleteSMBLead(filter: $filter) {
      id
      firstName
      lastName
      email
      phone
      region
      companyName
      inboundMethod
      refCode
      isPartner
      partnerEOI
      surveyEOI
      quizCompleted
      hasPurchased
      purchaseEOI
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTOTPChallenge = /* GraphQL */ `
  subscription OnCreateTOTPChallenge(
    $filter: ModelSubscriptionTOTPChallengeFilterInput
  ) {
    onCreateTOTPChallenge(filter: $filter) {
      id
      email
      phone
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTOTPChallenge = /* GraphQL */ `
  subscription OnUpdateTOTPChallenge(
    $filter: ModelSubscriptionTOTPChallengeFilterInput
  ) {
    onUpdateTOTPChallenge(filter: $filter) {
      id
      email
      phone
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTOTPChallenge = /* GraphQL */ `
  subscription OnDeleteTOTPChallenge(
    $filter: ModelSubscriptionTOTPChallengeFilterInput
  ) {
    onDeleteTOTPChallenge(filter: $filter) {
      id
      email
      phone
      code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMailingSubscription = /* GraphQL */ `
  subscription OnCreateMailingSubscription(
    $filter: ModelSubscriptionMailingSubscriptionFilterInput
  ) {
    onCreateMailingSubscription(filter: $filter) {
      id
      email
      phone
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMailingSubscription = /* GraphQL */ `
  subscription OnUpdateMailingSubscription(
    $filter: ModelSubscriptionMailingSubscriptionFilterInput
  ) {
    onUpdateMailingSubscription(filter: $filter) {
      id
      email
      phone
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMailingSubscription = /* GraphQL */ `
  subscription OnDeleteMailingSubscription(
    $filter: ModelSubscriptionMailingSubscriptionFilterInput
  ) {
    onDeleteMailingSubscription(filter: $filter) {
      id
      email
      phone
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInsuranceProvider = /* GraphQL */ `
  subscription OnCreateInsuranceProvider(
    $filter: ModelSubscriptionInsuranceProviderFilterInput
  ) {
    onCreateInsuranceProvider(filter: $filter) {
      id
      insuranceId
      name
      displayName
      descriptions
      website
      email
      emails
      logo
      regions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInsuranceProvider = /* GraphQL */ `
  subscription OnUpdateInsuranceProvider(
    $filter: ModelSubscriptionInsuranceProviderFilterInput
  ) {
    onUpdateInsuranceProvider(filter: $filter) {
      id
      insuranceId
      name
      displayName
      descriptions
      website
      email
      emails
      logo
      regions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInsuranceProvider = /* GraphQL */ `
  subscription OnDeleteInsuranceProvider(
    $filter: ModelSubscriptionInsuranceProviderFilterInput
  ) {
    onDeleteInsuranceProvider(filter: $filter) {
      id
      insuranceId
      name
      displayName
      descriptions
      website
      email
      emails
      logo
      regions
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInsuranceQuote = /* GraphQL */ `
  subscription OnCreateInsuranceQuote(
    $filter: ModelSubscriptionInsuranceQuoteFilterInput
  ) {
    onCreateInsuranceQuote(filter: $filter) {
      id
      insuranceId
      ccid
      orgId
      title
      firstName
      lastName
      role
      email
      telephone
      sendingEmails
      PIIPCI
      numEmployees
      revenueAmount
      signature
      signatureCompletedAt
      formCompletedAt
      declarationSigned
      pdfReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInsuranceQuote = /* GraphQL */ `
  subscription OnUpdateInsuranceQuote(
    $filter: ModelSubscriptionInsuranceQuoteFilterInput
  ) {
    onUpdateInsuranceQuote(filter: $filter) {
      id
      insuranceId
      ccid
      orgId
      title
      firstName
      lastName
      role
      email
      telephone
      sendingEmails
      PIIPCI
      numEmployees
      revenueAmount
      signature
      signatureCompletedAt
      formCompletedAt
      declarationSigned
      pdfReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInsuranceQuote = /* GraphQL */ `
  subscription OnDeleteInsuranceQuote(
    $filter: ModelSubscriptionInsuranceQuoteFilterInput
  ) {
    onDeleteInsuranceQuote(filter: $filter) {
      id
      insuranceId
      ccid
      orgId
      title
      firstName
      lastName
      role
      email
      telephone
      sendingEmails
      PIIPCI
      numEmployees
      revenueAmount
      signature
      signatureCompletedAt
      formCompletedAt
      declarationSigned
      pdfReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
