import {
  addNewUserToOrganisation,
  addPartnerAccount,
  getPartner,
  getPartnersByPhone,
  removePartnerAccount,
} from "api";
import { addNewReferral } from "api/functions/admin/referral";
import {
  PartnerOptionAdd,
  PartnerOptionAddReferrer,
  PartnerOptionAddUser,
  PartnerOptionRemove,
} from "components";
import { useState } from "react";
import { OrgIdService, capitalize } from "services";

export function AdminPagePartnerOptions() {
  const [internalLoading, setInternalLoading] = useState();
  const [searchResult, setSearchResult] = useState();
  // add/update partner
  const [addInput, setAddInput] = useState({});
  const [showAddPartnerPanel, setShowAddPartnerPanel] = useState(false);
  const [showAddPartnerConfirm, setShowAddPartnerConfirm] = useState(false);
  // remove/partner
  const [removeInput, setRemoveInput] = useState({});
  const [showRemovePartnerPanel, setShowRemovePartnerPanel] = useState(false);
  const [showRemovePartnerConfirm, setShowRemovePartnerConfirm] =
    useState(false);
  // add referrer
  const [addReferrerInput, setAddReferrerInput] = useState({});
  const [showAddReferrerPanel, setShowAddReferrerPanel] = useState(false);
  const [showAddReferrerConfirm, setShowAddReferrerConfirm] = useState(false);
  // add new user
  const [addNewUserInput, setAddNewUserInput] = useState({});
  const [showAddNewUserPanel, setShowAddNewUserPanel] = useState(false);
  const [showAddNewUserConfirm, setShowAddNewUserConfirm] = useState(false);
  const [addResult, setAddResult] = useState();
  const [showResult, setShowResult] = useState(false);

  const openAddPartnerPanel = () => {
    setShowAddPartnerPanel(true);
  };

  const closeAddPartnerPanel = () => {
    setShowAddPartnerPanel(false);
  };

  const openAddPartnerConfirm = () => {
    setShowAddPartnerConfirm(true);
  };

  const closeAddPartnerConfirm = () => {
    setShowAddPartnerConfirm(false);
  };

  const openRemovePartnerPanel = () => {
    setShowRemovePartnerPanel(true);
  };

  const closeRemovePartnerPanel = () => {
    setShowRemovePartnerPanel(false);
  };

  const openRemovePartnerConfirm = () => {
    setShowRemovePartnerConfirm(true);
  };

  const closeRemovePartnerConfirm = () => {
    setShowRemovePartnerConfirm(false);
  };

  const openAddReferrerPanel = () => {
    setShowAddReferrerPanel(true);
  };

  const closeAddReferrerPanel = () => {
    setShowAddReferrerPanel(false);
  };

  const openAddReferrerConfirm = () => {
    setShowAddPartnerConfirm(true);
  };

  const closeAddReferrerConfirm = () => {
    setShowAddPartnerConfirm(false);
  };

  const openAddNewUserPanel = () => {
    setShowAddNewUserPanel(true);
  };

  const closeAddNewUserPanel = () => {
    setShowAddNewUserPanel(false);
  };

  const openAddNewUserConfirm = () => {
    setShowAddNewUserConfirm(true);
  };

  const closeAddNewUserConfirm = () => {
    setShowAddNewUserConfirm(false);
  };

  const openResultPanel = () => {
    setShowResult(true);
  };

  const closeResultPanel = () => {
    setShowResult(false);
  };

  const handleAddInput = (event) => {
    setAddInput({
      ...addInput,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const handleRemoveInput = (event) => {
    setRemoveInput({
      ...removeInput,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const handleAddReferrerInput = (event) => {
    setAddReferrerInput({
      ...addReferrerInput,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const handleAddNewUserInput = (event) => {
    setAddNewUserInput({
      ...addNewUserInput,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const processPartnerAddInfo = async () => {
    setInternalLoading(true);

    let err;

    if (!addInput?.ABN) {
      err = "No ABN provided.";
    } else if (!addInput?.phone) {
      err = "Phone is mandatory.";
    } else {
      // check valid ABN
      const search = await OrgIdService.lookupOrganisation(addInput?.ABN);

      if (!search?.result) {
        err = "Invalid ABN.";
      } else {
        let processedInput = {
          ...addInput,
          name: capitalize(addInput?.name),
          ABN: addInput?.ABN?.replace(/ /g, ""),
          isTypeMSP: JSON.parse(addInput?.isTypeMSP || false),
          isTypeConsultant: JSON.parse(addInput?.isTypeConsultant || false),
          isTypeMembership: JSON.parse(addInput?.isTypeMembership || false),
          isTypeReferrer: JSON.parse(addInput?.isTypeReferrer || false),
          isTypeSupplyChain: JSON.parse(addInput?.isTypeSupplyChain || false),
          isTypeFranchise: JSON.parse(addInput?.isTypeFranchise || false),
          minimumVolume: JSON.parse(addInput?.minimumVolume || 0),
          volumeDiscount: JSON.parse(addInput?.volumeDiscount || 0),
          subscriptionDiscount: JSON.parse(addInput?.subscriptionDiscount || 0),
        };

        // check existing partner with phone
        const existedPhone = (await getPartnersByPhone(processedInput?.phone))
          ?.items;
        if (existedPhone?.length > 0) {
          err = "Phone invalid. Already linked to another Partner account.";
        } else {
          // check existing partner
          const partner = await searchPartner(processedInput?.ABN);

          processedInput = {
            ...processedInput,
            existed: !!partner ? true : false,
            isTypeCAP: !!partner ? partner?.isTypeCAP : true,
            isActive: !!partner ? partner?.isActive : false,
            isSetup: !!partner ? partner?.isSetup : false,
            name: processedInput?.name || processedInput?.entityName,
          };

          setSearchResult(processedInput);
          err = null;
          openAddPartnerConfirm();
        }
      }
    }

    setInternalLoading(false);
    return err;
  };

  const processPartnerRemoveInfo = async () => {
    setInternalLoading(true);

    const partner = await searchPartner(removeInput?.ABN);
    let err;
    if (!partner) {
      err = "Not found.";
    } else {
      setSearchResult(partner);
      err = null;
      openRemovePartnerConfirm();
    }

    setInternalLoading(false);
    return err;
  };

  const processPartnerAddReferrerInfo = async () => {};

  const processPartnerAddUserInfo = async () => {};

  const searchPartner = async (ABN) => {
    const partner = await getPartner(ABN);
    return partner;
  };

  const confirmAdd = async () => {
    setInternalLoading(true);
    closeAddPartnerConfirm();
    let err;

    try {
      if (!!searchResult) {
        await add();
      }
      closeAddPartnerPanel();
    } catch (e) {
      console.log(e);
      err = e.toString();
    }

    setInternalLoading(false);
    return err;
  };

  const confirmAddReferrer = async (referral) => {
    setInternalLoading(true);

    let err;

    let result = await addNewReferral(referral);

    console.log(result);

    if (!!result?.error) {
      err = result?.error;
    } else {
      // success
    }

    setInternalLoading(false);
    return err;
  };

  const confirmAddUser = async (user) => {
    setInternalLoading(true);

    let err;

    let result = await addNewUserToOrganisation({
      userEmail: user?.email,
      userPhone: user?.phone,
      userFullName: user?.fullName,
      ABN: user?.ABN,
    });

    console.log(result);

    if (!!result?.error) {
      err = result?.error;
    } else {
      // success
    }

    setInternalLoading(false);
    return err;
  };

  const confirmRemove = async () => {
    setInternalLoading(true);
    closeRemovePartnerConfirm();
    let err;

    await removePartnerAccount({ ABN: searchResult?.ABN });

    setInternalLoading(false);
    return err;
  };

  const add = async () => {
    setAddResult(await addPartnerAccount(searchResult));
    openResultPanel();
  };

  return (
    <>
      <div className="partner-option">
        {/* <p className="view">View all</p> */}
      </div>

      <div className="partner-option">
        <p className="view" onClick={openAddNewUserPanel}>
          Add new User
        </p>

        <p className="view" onClick={openAddReferrerPanel}>
          Add new Referrer
        </p>

        <p className="view" onClick={openAddPartnerPanel}>
          Add/update partner entry
        </p>

        <p className="view" onClick={openRemovePartnerPanel}>
          Remove partner entry
        </p>

        {/* <p
          className="view"
          onClick={async () => {
            let offerAccepts = await searchAllSpecialOfferAccepts();
            console.log(
              `UPDATE ${JSON.stringify(
                await Promise.all(
                  offerAccepts.map(async (offer) => {
                    let res = await updateSpecialOfferAccept({
                      id: offer?.id,
                      firstEmailActive: true,
                      firstEmailReminder: 7,
                      firstEmailTemplate:
                        "NOTIFICATION_PARTNER_SPECIAL_OFFER_1",
                      secondEmailActive: true,
                      secondEmailReminder: 1,
                      secondEmailTemplate:
                        "NOTIFICATION_PARTNER_SPECIAL_OFFER_2",
                    });
                    return res;
                  })
                )
              )}`
            );
          }}
        >
          TEMP: add partner special offer email props
        </p> */}
      </div>

      <PartnerOptionAdd
        input={addInput}
        cfm={searchResult}
        handleInput={handleAddInput}
        showPanel={showAddPartnerPanel}
        closePanel={closeAddPartnerPanel}
        showCfmPanel={showAddPartnerConfirm}
        closeCfmPanel={closeAddPartnerConfirm}
        process={processPartnerAddInfo}
        confirm={confirmAdd}
        showResult={showResult}
        closeResultPanel={closeResultPanel}
        addResult={addResult}
      />

      <PartnerOptionRemove
        input={removeInput}
        cfm={searchResult}
        handleInput={handleRemoveInput}
        showPanel={showRemovePartnerPanel}
        closePanel={closeRemovePartnerPanel}
        showCfmPanel={showRemovePartnerConfirm}
        closeCfmPanel={closeRemovePartnerConfirm}
        process={processPartnerRemoveInfo}
        confirm={confirmRemove}
      />

      <PartnerOptionAddReferrer
        input={addReferrerInput}
        handleInput={handleAddReferrerInput}
        showPanel={showAddReferrerPanel}
        closePanel={closeAddReferrerPanel}
        showCfmPanel={showAddReferrerConfirm}
        closeCfmPanel={closeAddReferrerConfirm}
        process={processPartnerAddReferrerInfo}
        confirm={confirmAddReferrer}
      />

      <PartnerOptionAddUser
        input={addNewUserInput}
        handleInput={handleAddNewUserInput}
        showPanel={showAddNewUserPanel}
        closePanel={closeAddNewUserPanel}
        showCfmPanel={showAddNewUserConfirm}
        closeCfmPanel={closeAddNewUserConfirm}
        process={processPartnerAddUserInfo}
        confirm={confirmAddUser}
      />
    </>
  );
}
